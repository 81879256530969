<template>
    <div class="contentPickup">
        <form action="/" method="POST" @submit.prevent="returnData">
            <h5 class="title">Pickup</h5>
            <div class="personalInfoContent group-row padding-bottom-1.5">
                <div>
                    <label class="label" for="full-name">Nombre y Apellido</label>
                    <input
                        type="text"
                        id="full-name"
                        v-model="form.name"
                        class="input-form"
                        :class="validationStatus(v$.form.name)"
                        placeholder="Nombre y apellido"
                    />
                    <div class="input-errors" v-for="error of v$.form.name.$errors" :key="error.$uid">
                        <div class="error-msg">{{ error.$message }}</div>
                    </div>
                </div>
                <div>
                    <label class="label" for="phone">Número de teléfono</label>
                    <input
                        type="tel"
                        v-model="form.phone"
                        name="phone"
                        id="phone"
                        class="input-form"
                        :class="validationStatus(v$.form.phone)"
                        placeholder="Número de teléfono"
                        pattern="[0-9]+"

                    />
                    <div class="input-errors" v-for="error of v$.form.phone.$errors" :key="error.$uid">
                        <div class="error-msg">{{ error.$message }}</div>
                    </div>
                </div>
            </div>
            <!--
            <div class="group-row">
                <label class="label" for="">Seleccione la forma de pago de tu pedido</label> <br> <br>
                <div class="select">
                    <select v-model="payFormat" name="slct" id="slct" @change="updatePayFormat">
                        <option selected disabled>Seleccione la forma de pago</option>
                        <option v-for="format in payFormats" :key="format.id" :value="format.id">{{format.title}}</option>
                    </select>
                </div> <br>
            </div>
            -->

            <h5 class="subtitle">Elige el método de pago del pedido</h5>
            <div class="optionsOrderTypes">
                <template v-for="shippingMethod of shippingMethods" :key="shippingMethod.id">
                    <template  v-for="payment of shippingMethod.payments" :key="payment.id">
                        <span>
                            <input
                                class="radio"
                                type="radio"
                                name="methodPay"
                                :id="payment.name"
                                :value="payment.name"
                                v-model="form.methodPay"
                            />
                            <label :for="payment.name">
                                {{ payment.type }}
                                <inline-svg
                                    :src="payment.icon_url"
                                    width="25"
                                    height="25"
                                    :aria-label="payment.type"
                                ></inline-svg>
                            </label>
                        </span>
                    </template>
                </template>
                <div class="input-errors" v-for="error of v$.form.methodPay.$errors" :key="error.$uid">
                    <div class="error-msg">{{ error.$message }}</div>
                </div>
            </div>
            <div>
                <h5 class="title">Instrucciones de pago</h5>
                <p>{{ getMethodPayDescription }}</p>
            </div>
            <div class="prices">
                <div class="row">
                    <p>Subtotal</p>
                    <p style="text-align:right;">L. {{amount}}</p>
                </div>
                <div class="row">
                    <p>Total</p>
                    <p style="text-align:right;">L. {{amount}}</p>
                </div>
            </div>
            <div class="deliveryButtons">
                <!-- Todo: this logos come from database, payments settings. -->
                <div class="logos" v-if="form.methodPay === 'online_with_card'">
                    <img :src="asset('images/visa-mastercard.svg')" alt="Pago con tarjetas Visa y Mastercard" style="max-width: 70px;">
                    <img :src="asset('images/cybersource.svg')" alt="CyberSource">
                    <img :src="asset('images/vendu-pay.svg')" alt="Vendu Pay">
                </div>
                <button class="btn" id="send-data" type="submit">
                    <template v-if="!isValidate">
                        <template v-if="form.methodPay==='online_with_card'">
                            <inline-svg
                                :src="asset('images/icons/secure.svg')"
                                aria-label="Pago Seguro en Línea"
                            ></inline-svg>
                            Ir al pago
                        </template>
                        <template v-else>
                            <inline-svg
                                :src="asset('images/icons/send.svg')"
                                aria-label="Enviar pedido"
                            ></inline-svg>
                            Enviar pedido
                        </template>
                    </template>
                    <span v-else class="lds-ellipsis"><span></span><span></span><span></span><span></span></span>
                </button>
            </div>
        </form>

    </div>
</template>

<script>
//import { Datetime } from 'vue-datetime';
//import 'vue-datetime/dist/vue-datetime.css';

import { useVuelidate } from '@vuelidate/core'
import { required, minLength, maxLength, helpers, numeric } from '@vuelidate/validators'

import axios from 'axios';

export default {
    setup () {
        return {
            v$: useVuelidate({ $lazy: true, $autoDirty: true }),
        }
    },
    props: {
        order: Object,
        store: Object,
        amount: String,
        storeId: String,
        orderId: String,
        token: String,
        returnUrl: String,
        shopName: Object,
        shippingMethods: Object,
    },
    components:{
        //datetime: Datetime
    },
    data() {
        return {
            form: {
                name: '',
                phone: '',
                methodPay: '',
            },
            isValidate: false,
            datetimeSelected: null,
            msgErrorHour: false,
            pickupTime: null,
            payFormat: 3,
            /*
            payFormats:[
                {
                    id: 1,
                    title: "Pago contra entrega en efectivo"
                },
                {
                    id: 2,
                    title: "Pago contra entrega con tarjeta"
                },
                {
                    id: 3,
                    title: "Pago seguro en línea"
                }
            ],
            */
            payFormatText: '',
            urlReturn:null,
            currentDatetime: "",
            orderComments: "",
            updateOrderForm: this.$inertia.form({}),
        }
    },
    validations () {
        return {
            form: {
                name: {
                    required: helpers.withMessage('Ingrese su nombre y apellido', required),
                    minLength: minLength(3)
                },
                phone: {
                    required: helpers.withMessage('Ingrese su número de teléfono', required),
                    numeric: helpers.withMessage('Solamente números son permitidos', numeric),
                    minLength: helpers.withMessage('Debe ingresar mínimo 8 dígitos', minLength(8)),
                    maxLength: helpers.withMessage('Debe ingresar máximo 8 dígitos', maxLength(8)),
                },
                methodPay: {
                    required: helpers.withMessage('Seleccionar un método de pago', required)
                },
            },
        }
    },
    computed: {
        getMethodPay(){
            //return this.methodsPayDetail[this.form.methodPay];
            if(this.form.methodPay) {
                let payment = this.shippingMethods[0].payments.filter(element => element.name === this.form.methodPay)[0];

                return payment;
            }
        },
        getMethodPayDescription(){
            //return this.methodsPayDetail[this.form.methodPay];
            if(this.form.methodPay) {
                let payment = this.shippingMethods[0].payments.find(element => element.name === this.form.methodPay);

                if(payment) {
                    return payment.description;
                }

                return "Por favor selecciona un método de pago.";
            }
        }
    },
    methods:{
        validationStatus(validation) {
            return {
                error: validation.$error,
                dirty: validation.$dirty
            }
        },
        async returnData(){

            const isFormCorrect = await this.v$.$validate()

            this.isValidate = true;

            // you can show some extra alert to the user
            if (!isFormCorrect)
            {
                this.isValidate = false;
                // notify user form is invalid
                return
            }

            /*
            let urls = {
                "31552060": "&clientId=custom-app-31552060-1",
                "43188072": "&clientId=custom-app-43188072-1",
                "43195065": "&clientId=custom-app-43195065-1",
                "40993297": "&clientId=custom-app-40993297-1",
                "44727221": "&clientId=custom-app-44727221-1",
                "32268563": "&clientId=custom-app-32268563-2",
                "55436462": "&clientId=custom-app-55436462-1"
            }
            */
            let urlPayApp = null;
            //let urlStore = null;

            if(isFormCorrect)
            {
                this.isValidate = true;
                let storeId = this.storeId;
                let url = `https://app.ecwid.com/api/v3/${this.storeId}/orders/transaction_${this.orderId}?token=${this.token}`;

                let params = {
                    paymentStatus: 'AWAITING_PAYMENT',
                    fulfillmentStatus: 'AWAITING_PROCESSING',
                    //"paymentModule":"CUSTOM_PAYMENT_APP-mollie-pg",
                    //"paymentMethod":"Credit or debit card (Mollie)",
                    paymentMethod: this.getMethodPay.type,
                    orderComments: this.orderComments,
                    //pickupTime: this.pickupTime,
                    shippingOption:{
                        shippingMethodName: 'Pickup',
                        fulfillmentType: 'PICKUP',
                        //fulfilmentType: 'PICKUP',
                        isPickup: true
                    },
                    billingPerson: {
                        name: this.form.name,
                        street: `${this.$page.props.store.city.name}, ${this.$page.props.store.country.name}`,
                        city: this.$page.props.store.city.name,
                        countryCode: this.$page.props.store.country.iso, //"HN"
                        countryName: this.$page.props.store.country.name, //"Honduras"
                        postalCode:  this.$page.props.store.city.city_code,
                        phone: "+504"+this.form.phone,
                    },
                    shippingPerson: {
                        name: this.form.name,
                        street: `${this.$page.props.store.city.name}, ${this.$page.props.store.country.name}`,
                        city: this.$page.props.store.city.name,
                        countryCode: this.$page.props.store.country.iso, //"HN"
                        countryName: this.$page.props.store.country.name, //"Honduras"
                        postalCode:  this.$page.props.store.city.city_code,
                        phone: "+504"+this.form.phone,
                    }
                };

                let updateOrderData = {
                    paymentProvider: this.getMethodPay.name,
                    paymentMethod: this.getMethodPay.type,
                    store: {
                        uuid: this.store.uuid
                    },
                    order: {
                        uuid: this.order.uuid
                    },
                    clientReferenceInformation: {
                        code: this.orderId //"TC50171_3"
                    },
                    shippingInformation: {
                        // shippingCarrierName: this.shippingData.params.shippingOption.shippingCarrierName || '', // input optional in Pickup
                        shippingMethodId: 0,
                        shippingMethodName: 'Pickup',
                        fulfillmentType: 'PICKUP',
                        shippingRate: 0.00,
                        //fulfilmentType: 'DELIVERY',
                        isPickup: true,
                    },
                    orderInformation: {
                        amountDetails: {
                            totalAmount: this.amount,
                            currency: this.store.currency, // "HNL" //USD //HNL
                        },
                        billTo: {
                            name: this.form.name,
                            firstName: this.form.name,
                            lastName: this.form.name,
                            address1: 'no solicitada',
                            locality: this.$page.props.store.city.name,
                            //administrativeArea: "FM", // Todo: call this parameter from the database,
                            postalCode:  this.$page.props.store.city.city_code, //"11101" || this.deliveryPostalCode,
                            country: this.$page.props.store.country.iso, //this.deliveryCountry || 'Honduras',
                            email: this.$page.props.order.ecwid_order_email,
                            phoneNumber: "+504"+this.form.phone, //this.deliveryPhone
                        }
                    }
                }

                if(this.form.methodPay !== "online_with_card"){ // pago contra entrega con tarjeta - pago contra entrega con efectivo
                    try {

                        let returnUrl = this.returnUrl;

                        this.updateOrderForm
                            .transform((data) => (updateOrderData))
                            .post(route('pay.checkout.order.update', { store: this.store.uuid,order: this.order.uuid } ), {
                                onSuccess: (res) => {

                                    console.log({"Pickup: Se actualizo la orden": res, 'returnUrl': returnUrl})

                                    /*
                                    * Update Order on Ecwid
                                    */
                                    axios.put(url, params).then(res => {
                                        window.location.replace(returnUrl);
                                    }).catch(err => console.log(err));



                                },
                            })

                        //const orderUpdatePaymentStatus = await axios.put(url, params);
                        //let shippingPerson = Object.assign({'shippingPerson': {}}, params.shippingPerson);
                        //const orderUpdateShippingPerson = await axios.put(url, shippingPerson);
                        //console.log("orderUpdateShippingPerson: ", orderUpdatePaymentStatus)

                        /*
                        setTimeout(function() {
                            window.location.replace(returnUrl);
                        }, 2000)
                        */

                    }
                    catch(errors){
                        console.log("Errors pickup update order: ", errors)
                    }

                    /* axios.put(url, params).then(res => { */
                        /*
                        axios.put(url, params).then(resp => {
                            urlStore = this.returnUrl;
                            setTimeout(function() {
                                window.location.replace(urlStore)
                            }, 2000)
                        }).catch(err => console.log(err));
                        */
                    /* }).catch(err => console.log(err)); */
                } else if(this.form.methodPay === "online_with_card"){// pago seguro en línea

                    params.paymentStatus = 'PAID';
                    params.fulfillmentStatus = 'PROCESSING';

                    this.$emit("returnData", {
                        name: this.form.name,
                        phoneNumber: this.form.phone,
                        //pickupTime: this.pickupTime,
                        orderType: 'Pago seguro en línea',
                        params
                    })
                }
            }
        },

        /*
        updatePayFormat(){
            this.payFormatText = this.payFormats.filter(f => f.id === this.payFormat)[0].title;
        },
        */

    },
    created(){
        //this.payFormatText = this.payFormats.filter(f => f.id === this.payFormat)[0].title;

        /*
        axios.get(`https://app.ecwid.com/api/v3/${this.storeId}/orders/${this.orderId}?token=${this.token}`)
            .then(resp => {
            let data = resp.data;
                this.orderComments = data.orderComments;
            }).catch(err => console.log(err))
         */
    },
    mounted(){
        this.msgErrorHour = false;
        this.orderComments = this.order.ecwid_order_comments;
        //this.urlReturn = this.returnUrl;

        /*
        this.$nextTick(() => {

            if(this.shippingMethods[0].payments)
            {

                console.log("updated Payemnts Pickups: ", this.shippingMethods[0].payments);

                let paymentOnlineWithCard = this.shippingMethods[0].payments.find(element => element.name === 'online_with_card');

                if(paymentOnlineWithCard) {
                    this.form.methodPay = paymentOnlineWithCard.name
                }

                this.form.methodPay = this.shippingMethods[0].payments[0].name || "";

            }


        });
        */



    },
    updated(){

    }
}
</script>

<style lang="scss">
    .contentPickup {
        text-align: left;
        width: 100%;

        h5 {
            //display: none;
            font-weight: 400;

            @media screen and (min-width: 768px){
                display: block;
            }
        }

        h5.title {
            font-weight: 600;
            letter-spacing: 0;
            font-size: 0.94rem;
            margin-bottom: 0.7rem;
        }

        h5.subtitle {
            font-weight: 600;
            letter-spacing: 0;
            font-size: 0.85rem;
            margin-bottom: 0.7rem;
        }

        .personalInfoContent {
            display: grid;
            grid-gap: 1rem;
            grid-template-columns: repeat(1, 1fr);

            @media screen and (min-width: 768px) {
                grid-template-columns: repeat(2, 1fr);
            }
        }

        .optionsOrderTypes {
            display: grid;
            grid-gap: 1rem;
            grid-template-columns: repeat(1, 1fr);
            justify-items: stretch;
            text-align: left;
            margin-bottom: 1.5rem;

            @media screen and (min-width: 1000px) {
                grid-template-columns: repeat(2, 1fr);
            }

            & svg {
                //margin-right: 1rem;
                fill: #BDC7D4 !important;
            }

            & svg g path, polygon {
                fill: #BDC7D4;
            }

            svg > path {
                fill: #BDC7D4;
            }

            input[type="radio"] {
                display: none;

                &:checked + label {
                    border: 1.5px solid #0A99FE;
                    background-color: #eff6fb;
                    //font-weight: 600;
                }
            }

            label {
                padding: 0.5rem 1rem;
                border-radius: 5px;
                border: 1.5px solid #BDC7D4;
                display: flex;
                align-content: center;
                justify-content: space-between;
                align-items: center;
                position: relative;
                cursor: pointer;
                font-size: 0.75rem;
                font-weight: 600;
                width: 100%;
                min-height: 65px;
                -webkit-transition: all 0.3s ease-in-out;
                transition: all 0.3s ease-in-out;

                &:hover {
                    border: 1.5px solid #0A99FE;
                    background-color: #eff6fb;
                }

            }
        }

        .optionsOrderTypes input[type="radio"]:checked + label > svg > g > path,
        .optionsOrderTypes input[type="radio"]:checked + label > svg > g > polygon,
        .optionsOrderTypes input[type="radio"]:checked + label > svg > path,
        .optionsOrderTypes input[type="radio"]:checked + label > svg > polygon
        {
            fill: #0A99FE;
        }

        .prices {
            margin-top:1rem;

            .row {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                font-size: .9rem;

                &:last-child{
                    border-top: solid 1px #ccc;
                    font-size: 1.1rem;
                    font-weight: bold;
                    padding-top:.7rem;
                }
            }
            @media screen and (min-width: 768px){
                display: none;
            }
        }

        .btn {
            //margin-top: 2rem;
            //width: 100%;
        }

        @media screen and (min-width: 768px){
            //width: 300px;
            p {
                padding-bottom: 0.5rem;
                //display: block;
                font-size: 0.75rem;
            }

            .btn {
                //width: 10rem;
            }
        }
        @media screen and (min-width: 1024px){
            width: auto;
        }
    }
</style>
