<template>
  <section class="alert">
    <div class="alert__content">
        <div class="alert__content--icon">

            <LottieAnimation
                :path="data.animationPath"
                :width="256"
                :height="256"
            />

        </div>
        <div class="alert__content--title">
          <h2>{{data.title}}</h2>
        </div>
        <div class="alert__content--description">
          <p>{{data.description}}</p>
        </div>
    </div>
  </section>
</template>
<!--
<script src="https://unpkg.com/@lottiefiles/lottie-interactivity@latest/dist/lottie-interactivity.min.js"></script>
-->
<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
export default {
  name: 'Alert',
  props: {
    data: Object,
  },
  components:{
    LottieAnimation,
  },
  data () {
    return {

    }
  },
  methods:{
  },
  mounted () {
      /*
    LottieInteractivity.create({
          mode:"scroll",
          player: "#fifthLottie",
          actions: [
              {
                  visibility:[0, 1],
                  type: "loop",
                  frames: [15.5, 31]
              }
          ]
    });

       */
  }


}
</script>
