<template>
    <div class="deliveryContent">
        <form action="/" method="POST" @submit.prevent="returnData">
            <!-- <h5>DIRECCIÓN DE ENVÍO</h5> -->
            <h5 class="subtitle">Zonas de delivery disponibles</h5>
            <!--
            <p
                v-for="error of v$.$errors"
                :key="error.$uid"
            >
                <strong>{{ error.$validator }}</strong>
                <small> on property</small>
                <strong>{{ error.$property }}</strong>
                <small> says:</small>
                <strong>{{ error.$message }}</strong>
            </p>
            -->

            <div class="group-row padding-bottom-0.5">
                <vSelect
                    v-model="form.addressSelected"
                    name="addressSelected"
                    :options="neighborhoods"
                    label="name"
                    class="selectAddress"
                    :class="validationStatus(v$.form.addressDetails)"
                    v-on:option:selected="coloniaSeleccionada"
                    placeholder="Escribe el nombre de tu barrio o colonia"
                    v-on:option:deselected="setAmountDelivery(0)"
                >
                    <div slot="no-options">
                        No hay coincidencias con ese barrio o colonia.
                    </div>
                </vSelect>
                <div class="input-errors" v-for="error of v$.form.addressSelected.$errors" :key="error.$uid">
                    <div class="error-msg">{{ error.$message }}</div>
                </div>
            </div>
            <div class="group-row padding-bottom-1.5">
                <!-- <label class="label" for="">Detalle de la dirección</label><br /> -->
                <input
                    v-model="form.addressDetails"
                    type="text"
                    class="input-form"
                    :class="validationStatus(v$.form.addressDetails)"
                    placeholder="Escribe con detalle la calle, avenida, número de casa"
                />
                <div class="input-errors" v-for="error of v$.form.addressDetails.$errors" :key="error.$uid">
                    <div class="error-msg">{{ error.$message }}</div>
                </div>
            </div>
            <!-- <h5>DATOS DE CONTACTO</h5>-->
            <h5 class="subtitle">Escribe tus datos de contacto</h5>
            <div class="personalInfoContent group-row padding-bottom-1.5">
                <div>
                    <label class="label hidden" for="full-name">Nombre y Apellido</label>
                    <input
                        type="text"
                        id="full-name"
                        v-model="form.name"
                        class="input-form"
                        :class="validationStatus(v$.form.name)"
                        placeholder="Nombre y apellido"
                    />
                    <div class="input-errors" v-for="error of v$.form.name.$errors" :key="error.$uid">
                        <div class="error-msg">{{ error.$message }}</div>
                    </div>
                </div>
                <div>
                    <label class="label hidden" for="phone">Número de teléfono</label>
                    <input
                        type="tel"
                        v-model="form.phone"
                        name="phone"
                        id="phone"
                        class="input-form"
                        :class="validationStatus(v$.form.phone)"
                        placeholder="Número de teléfono"
                        pattern="[0-9]+"

                    />
                    <div class="input-errors" v-for="error of v$.form.phone.$errors" :key="error.$uid">
                        <div class="error-msg">{{ error.$message }}</div>
                    </div>
                </div>
            </div>
            <h5 class="subtitle">Elige el método de pago del pedido</h5>
            <div class="optionsOrderTypes">
                <template v-for="shippingMethod of shippingMethods" :key="shippingMethod.id" >
                    <template  v-for="payment of shippingMethod.payments" :key="payment.id">
                        <span>
                            <input
                                class="radio"
                                type="radio"
                                name="methodPay"
                                :id="payment.name"
                                :value="payment.name"
                                v-model="form.methodPay"
                            />
                            <label :for="payment.name">
                                {{ payment.type }}
                                <inline-svg
                                    :src="payment.icon_url"
                                    width="25"
                                    height="25"
                                    :aria-label="payment.type"
                                ></inline-svg>
                            </label>
                        </span>
                    </template>
                </template>
                <div class="input-errors" v-for="error of v$.form.methodPay.$errors" :key="error.$uid">
                    <div class="error-msg">{{ error.$message }}</div>
                </div>
                <!--
                <span>
                    <input
                        class="radio"
                        type="radio"
                        name="methodPay"
                        id="payWithCard"
                        value="payWithCard"
                        v-model="methodPay"
                    />
                    <label for="payWithCard">Pago seguro en línea</label>
                </span>
                <span>
                    <input
                        class="radio"
                        type="radio"
                        name="methodPay"
                        id="payOnDelivery"
                        value="payOnDelivery"
                        v-model="methodPay"
                    />
                    <label for="payOnDelivery">{{getTypeMethodPay}}</label>
                </span>
                -->
            </div>
            <div>
                <h5 class="title">Instrucciones de pago</h5>
                <p>{{ getMethodPayDescription }}</p>
            </div>

            <div class="prices">
                <div class="prices__row">
                    <p>Subtotal</p>
                    <p style="text-align:right;">L. {{ getAmount }}</p>
                </div>
                <div class="prices__row" style="font-weight:600; color: #00C3FF;">
                    <p>+ Delivery</p>
                    <p style="text-align:right;">L. {{ getAmountDelivery }}</p>
                </div>
                <div class="prices__row" style="font-weight:600;">
                    <p>Total a Pagar</p>
                    <p style="text-align:right;">L. {{ getTotal }}</p>
                </div>
            </div>
            <div class="deliveryButtons">
                <!-- Todo: this logos come from database, payments settings. -->
                <div class="logos" v-if="form.methodPay === 'online_with_card'">
                    <img :src="asset('images/visa-mastercard.svg')" alt="Pago con tarjetas Visa y Mastercard" style="max-width: 70px;">
                    <img :src="asset('images/cybersource.svg')" alt="CyberSource">
                    <img :src="asset('images/vendu-pay.svg')" alt="Vendu Pay">
                </div>
                <button class="btn" id="send-data" type="submit">
                    <template v-if="!isValidate">
                        <template v-if="form.methodPay==='online_with_card'">
                            <inline-svg
                                :src="asset('images/icons/secure.svg')"
                                aria-label="Pago Seguro en Línea"
                            ></inline-svg>
                            Ir al pago
                        </template>
                        <template v-else>
                            <inline-svg
                                :src="asset('images/icons/send.svg')"
                                aria-label="Enviar pedido"
                            ></inline-svg>
                            Enviar pedido
                        </template>
                    </template>
                    <span v-else class="lds-ellipsis"><span></span><span></span><span></span><span></span></span>
                </button>
            </div>
        </form>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import { useVuelidate } from '@vuelidate/core'
import { email, required, minLength, maxLength, helpers, numeric } from '@vuelidate/validators'

import axios from 'axios';

export default {
    setup () {
        return {
            v$: useVuelidate({ $lazy: true, $autoDirty: true }),
        }
    },
    props:{
        order: Object,
        options: Object,
        shopDetails: Object,
        neighborhoods: Object,
        shippingMethods: Object,
        store: Object,
    },
    components:{
        vSelect,
    },
    data(){
        return {
            form: {
                addressSelected: null,
                addressDetails: '',
                name: '',
                phone: '',
                methodPay: '',
            },
            updateOrderForm: this.$inertia.form({}),
            addressOptions: [],
            currentPickupStore: {},
            amountDelivery: 0,
            loadingFareEstimate: false,
            isValidate: false,
            distanceDelivery: 0,
            emailCustomer: '',
            orderComments: '',
            methodsPayDetail: {
                "payWithCard": "Paga en línea con tu tarjeta de crédito o débito, el procesamiento es seguro, no almacenamos ningún dato de tu tarjeta",
                "payOnDelivery": "Paga contra entrega en efectivo, cuando el delivery llegue a tu dirección de entrega."
            }
        }
    },
    validations () {
        return {
            form: {
                addressSelected: {
                    required: helpers.withMessage('Seleccionar un barrio o colonia', required)
                },
                addressDetails: {
                    required: helpers.withMessage('Ingrese su dirección', required)
                },
                name: {
                    required: helpers.withMessage('Ingrese su nombre y apellido', required),
                    minLength: helpers.withMessage('Debe ingresar mínimo 3 letras', minLength(3)),
                },
                phone: {
                    required: helpers.withMessage('Ingrese su número de teléfono', required),
                    numeric: helpers.withMessage('Solamente números son permitidos', numeric),
                    minLength: helpers.withMessage('Debe ingresar mínimo 8 dígitos', minLength(8)),
                    maxLength: helpers.withMessage('Debe ingresar máximo 8 dígitos', maxLength(8)),
                },
                methodPay: {
                    required: helpers.withMessage('Seleccionar un método de pago', required)
                },
            },
        }
    },
    computed: {
        getAmountDelivery()
        {
            return Number(this.amountDelivery).toFixed(2);
        },
        getTotal () {
            this.total = Number(this.options.amount) + Number(this.amountDelivery);
            return (this.total).toFixed(2);
        },
        getAmount() {
            return Number(this.options.amount).toFixed(2);
        },
        /*
        getTypeMethodPay(){
            return this.labelsMethodsPay[this.options.storeId];
        },
        */
        getMethodPayDescription(){
            //return this.methodsPayDetail[this.form.methodPay];

            if(this.form.methodPay) {
                let payment = this.shippingMethods[0].payments.find(element => element.name === this.form.methodPay);

                if(payment) {
                    return payment.description;
                }

                return "Por favor selecciona un método de pago.";

            }


        },
        getMethodPay(){
            //return this.methodsPayDetail[this.form.methodPay];
            if(this.form.methodPay) {
                let payment = this.shippingMethods[0].payments.find(element => element.name === this.form.methodPay);

                return payment;
            }
        }
    },
    methods:{
        validationStatus(validation) {
            return {
                error: validation.$error,
                dirty: validation.$dirty
            }
        },
        setAmountDelivery(amountDelivery)
        {
            this.amountDelivery = amountDelivery;

            this.$emit('returnFareEstimate', {
                loadingFareEstimate: false,
                amountDelivery: this.getAmountDelivery,
                total: this.total,
            });
        },
        fetchOptions(search, loading){
            loading(true)

            this.$emit('returnFareEstimate', {
                loadingFareEstimate: true
            });
            if(search.length >= 3){
                fetch(`https://symmetrisk-pay.azurewebsites.net/api/Colonia/searchgranlinfa?direccion=${search}`)
                .then(res => {
                    res.json().then(json => {
                        this.addressOptions = json.data;
                        if (this.addressOptions.length > 0 ) { loading(false) }
                    });
                });
            }
            if (this.form.addressSelected !== null) { loading(false) }
        },
        async getFareEstimate(data) {
            try {
                const response = await axios.post('https://integrations.yovoyenvios.com/api/delivery/fare-estimate', data );
                return response.data;
            } catch (error) {
                return { fare: 92, distance: 1000};
            }
        },
        async getAddress(){
            let storeId = Number(this.options.storeId);
            let currentStoreIds = [storeId];
            if(storeId === 31552060 || storeId === 43188072
            || storeId === 43195065){
                currentStoreIds = [31552060, 43188072, 43195065];
            }
            console.log(currentStoreIds);
 /*
            const resp = (await axios.post(`https://symmetrisk-pay.azurewebsites.net/api/Colonia/searchgranlinfa`, {
                storeIds: currentStoreIds
            })).data
            this.addressOptions = resp.data;
            /* resp.data.map(col => ({
                id: col.id, direccion: capitalize(col.direccion),
                longitud: col.longitud,
                latitud: col.latitud,
                preStoreId: col.preStoreId,
                storeId: col.storeId,
                precio: col.precio
            })).sort((a, b) => {
                if(a.direccion < b.direccion)
                    return -1;

                if(a.direccion > b.direccion)
                    return 1;

                return 0;
            }); */
        },
        coloniaSeleccionada(){
            //console.log(this.form.addressSelected);
            console.log("Dirección seleccionada: ", this.form.addressSelected);

            this.loadingFareEstimate = true;

            if(
                this.form.addressSelected.latitude
                && this.form.addressSelected.longitude
                && typeof this.shippingMethods[0].courierable !== 'undefined'
                && this.shippingMethods[0].courierable !== null
            )
            {
                const params = {
                    pickup: {
                        latitude : this.shippingMethods[0].courierable.latitude, // pedir la lista de lat y longitud de las sucursales
                        longitude : this.shippingMethods[0].courierable.longitude
                    },
                    delivery: {
                        latitude: this.form.addressSelected.latitude, //this.form.addressSelected.Latitud,
                        longitude: this.form.addressSelected.longitude //this.form.addressSelected.Longitud
                    },
                    vehicle: 0,
                    // TODO: call this param from database
                    apiToken: "iD4fNt4li3QfuvkL0OdzcG9S51Ao1WNO"
                }

                //console.log("Delivery params", params);

                this.getFareEstimate(params)
                    .then( response => {
                        console.log("Se estableció el Delivery desde Yo Voy Api.", response );
                        this.amountDelivery = Number(response.fare);
                        //this.amountDelivery = Number(2.00);
                        this.distanceDelivery = response.distance;
                    })
                    .catch(response => {
                        console.log("Se estableció el Delivery por que fallo.", response );
                        this.amountDelivery = Number(response.fare);
                        this.distanceDelivery = response.distance;
                    })
                    .finally(() => {

                        this.loadingFareEstimate = false;

                        this.$emit('returnFareEstimate', {
                            loadingFareEstimate: false,
                            amountDelivery: this.amountDelivery,
                            total: this.total,
                        });

                    })
            } else {
                this.amountDelivery = Number(this.form.addressSelected.price);
                this.distanceDelivery = 1000;

                this.loadingFareEstimate = false;

                this.$emit('returnFareEstimate', {
                    loadingFareEstimate: false,
                    amountDelivery: this.getAmountDelivery,
                    total: this.total,
                });

            }

            /*
            this.$emit('returnFareEstimate', {
                loadingFareEstimate: true
            });
            */


        },
        async returnData(){

            const isFormCorrect = await this.v$.$validate()

            this.isValidate = true;

            // you can show some extra alert to the user
            if (!isFormCorrect)
            {
                this.isValidate = false;
                // notify user form is invalid
                return
            }

            // actually submit form

            let storeId = this.options.storeId;
            //let methodPays = this.labelsMethodsPay;
            let orderComments = this.orderComments;
            let distanceDelivery = this.distanceDelivery;
            const storeIdYoVoy = ["31552060", "43195065", "43188072", "55436462"]
            let urlPayApp = null;
            let urlStore = null;

            let that = this;

            function getMethodPayText(methodPay) {
                //return methodPay === 'payOnDelivery' ? methodPays[storeId] : 'Pago seguro en línea';
                //let payment = this.shippingMethods[0].payments.filter(element => element.name === this.form.methodPay)[0];

                console.log ("shippingMethods: ", that.shippingMethods)

                //return payment.name;


            }

            function getOrderComments(methodPay, shippingMethods) {
                //if(methodPay === 'online_with_card' && storeIdYoVoy.includes(storeId)){
                if(methodPay === 'online_with_card'
                && typeof shippingMethods[0].courierable !== 'undefined'
                && shippingMethods[0].courierable !== null
                ){
                    return `${orderComments} - La distancia del delivery es de ${distanceDelivery} m`;
                } else if (methodPay === 'online_with_card'){
                    return orderComments;
                }else{
                    return orderComments;
                }
            }


            /*
            if(
                (this.form.addressSelected !== null && this.form.addressSelected.name !== undefined)
                && this.form.addressDetails !== ""
                && this.form.name !== null && this.form.phone.length === 8
                && this.form.methodPay !== null
                && this.amountDelivery > 0
            )
            */
            if(isFormCorrect)
            {
                this.isValidate = true;

                let url = `https://app.ecwid.com/api/v3/${this.options.storeId}/orders/transaction_${this.options.orderID}?token=${this.options.tokenf}`;

                let params = {
                    total: this.total,
                    paymentStatus: this.form.methodPay === "online_with_card" ? 'PAID' : 'AWAITING_PAYMENT' ,
                    fulfillmentStatus: this.form.methodPay === "online_with_card" ? 'PROCESSING' : 'PROCESSING', //
                    //"paymentModule":"CUSTOM_PAYMENT_APP-mollie-pg",
                    //"paymentMethod":"Credit or debit card (Mollie)",
                    paymentMethod: this.getMethodPay.type,
                    orderComments: getOrderComments(this.form.methodPay, this.shippingMethods),
                    shippingOption: {
                        shippingCarrierName: typeof this.shippingMethods[0].courierable !== 'undefined' ? 'Delivery Yo Voy' : 'Delivery Propio',
                        shippingMethodName: 'Delivery',
                        fulfillmentType: 'DELIVERY',
                        shippingRate: Number(this.amountDelivery),
                        //fulfilmentType: 'DELIVERY',
                        isPickup: false,
                    },
                    billingPerson: {
                        name: this.form.name,
                        street: `${this.form.addressSelected.name}, ${this.form.addressDetails}`,
                        city: this.$page.props.store.city.name,
                        countryCode: this.$page.props.store.country.iso, //"HN"
                        countryName: this.$page.props.store.country.name, //"Honduras"
                        postalCode:  this.$page.props.store.city.city_code,
                        phone: "+504"+this.form.phone,
                    },
                    shippingPerson: {
                        name: this.form.name,
                        street: `${this.form.addressSelected.name}, ${this.form.addressDetails}`,
                        city: this.$page.props.store.city.name,
                        countryCode: this.$page.props.store.country.iso, //"HN"
                        countryName: this.$page.props.store.country.name, //"Honduras"
                        postalCode:  this.$page.props.store.city.city_code,
                        phone: "+504"+this.form.phone,
                    }
                };

                let updateOrderData = {
                    paymentProvider: this.getMethodPay.name,
                    paymentMethod: this.getMethodPay.type,
                    store: {
                        uuid: this.store.uuid
                    },
                    order: {
                        uuid: this.order.uuid
                    },
                    clientReferenceInformation: {
                        code: this.options.orderID //"TC50171_3"
                    },
                    shippingInformation: {
                        // shippingCarrierName: this.shippingData.params.shippingOption.shippingCarrierName || '', // input optional in Pickup
                        shippingMethodId: this.shippingMethods[0] ? this.shippingMethods[0].id : 0,
                        shippingMethodName: 'Delivery',
                        fulfillmentType: 'DELIVERY',
                        shippingRate: Number(this.amountDelivery),
                        //fulfilmentType: 'DELIVERY',
                        isPickup: false,
                    },
                    orderInformation: {
                        amountDetails: {
                            totalAmount: this.getTotal,
                            currency: this.store.currency, // "HNL" //USD //HNL
                        },
                        billTo: {
                            name: this.form.name,
                            firstName: this.form.name,
                            lastName: this.form.name,
                            address1: `${this.form.addressSelected.name}, ${this.form.addressDetails}`,
                            locality: this.$page.props.store.city.name,
                            //administrativeArea: "FM", // Todo: call this parameter from the database,
                            postalCode:  this.$page.props.store.city.city_code, //"11101" || this.deliveryPostalCode,
                            country: this.$page.props.store.country.iso, //this.deliveryCountry || 'Honduras',
                            email: this.$page.props.order.ecwid_order_email,
                            phoneNumber: "+504"+this.form.phone, //this.deliveryPhone
                        }
                    }
                }
                //return false;

                if(this.form.methodPay !== "online_with_card"){
                    //if(storeIdYoVoy.includes(this.options.storeId)){

                    /*
                    * Si el método de envío con proveedor externo (Ejemplo yo voy)
                     */
                    if(typeof this.shippingMethods[0].courierable !== 'undefined' && this.shippingMethods[0].courierable ){

                        console.log("Método de envío con proveedor externo (Ejemplo yo voy)")

                        let urlStore = this.options.returnUrl;

                        await this.deliveryYoVoy().then(res => {

                            //console.log("Redireccionamiento cuando es Delivery con Yo Voy: ", urlStore)

                            if(res){

                                /*
                                * Update order on backend
                                */

                                this.updateOrderForm
                                    .transform((data) => (updateOrderData))
                                    .post(route('pay.checkout.order.update', { store: this.store.uuid,order: this.order.uuid } ), {
                                        onSuccess: (res) => {
                                            console.log({"Se actualizo la orden": res})


                                            //axios.put(url, params).then(res => {
                                                axios.put(url, params).then(resp => {
                                                    window.location.replace(urlStore);
                                                }).catch(err => console.log(err));
                                            //}).catch(err => console.log(err));


                                        },
                                })



                            }
                        }).catch(err => console.log(err));

                        /*

                        this.createDeliveryYoVoy({
                            shopId: Number(this.options.storeId),
                            deliveryPhone: this.form.phone,
                            deliveryEmail: this.emailCustomer,
                            deliveryAddress: this.form.addressSelected.direccion,
                            deliveryName: `${this.form.name} - ${this.options.orderID}`,
                            deliveryLatitud: this.form.addressSelected.latitud,
                            deliveryLongitud: this.form.addressSelected.longitud,
                            orderComments: params.orderComments
                        }).then(resp => {
                            console.log(resp);
                            if(resp){
                                axios.put(url, params).then(res => {
                                    urlStore = this.options.returnUrl;
                                    urlPayApp = urls[this.options.storeId];
                                    axios.put(url, params).then(resp => {
                                        window.location.replace(urlStore + urlPayApp);
                                    }).catch(err => console.log(err));
                                }).catch(err => console.log(err));
                            }
                        }).catch(err => console.log(err));
                        */
                    }
                    else
                    {
                        /*
                        * Método de envío de propio del comercio
                         */
                        urlStore = this.options.returnUrl;

                        console.log("Método de envío de propio del comercio")

                        try {

                            /*
                            * Update the order on backend
                             */

                            this.updateOrderForm
                                .transform((data) => (updateOrderData))
                                .post(route('pay.checkout.order.update', { store: this.store.uuid, order: this.order.uuid } ), {

                                    onSuccess: (res) => {

                                        console.log({"Se actualizo la orden": res})


                                        //axios.put(url, params).then(res => {
                                            axios.put(url, params).then(resp => {
                                                window.location.replace(urlStore);
                                            }).catch(err => console.log(err));
                                        //}).catch(err => console.log(err));


                                        /*
                                        const orderUpdatePaymentStatus = await axios.put(url, params);
                                        //let shippingPerson = Object.assign({'shippingPerson': {}}, params.shippingPerson);
                                        const orderUpdateShippingPerson = await axios.put(url, params);

                                        console.log("orderUpdateShippingPerson: ", orderUpdateShippingPerson)

                                        setTimeout(function() {
                                            window.location.replace(urlStore);
                                        }, 2000)
                                        */

                                },
                            })



                        }
                        catch(errors){
                            console.log("Errors delivery update order: ", errors)
                        }

                        /*
                        axios.put(url, params).then(res => {

                            //urlPayApp = urls[this.options.storeId];

                            //window.location.replace(urlStore + urlPayApp);
                            window.location.replace(urlStore);

                            //axios.put(url, params).then(resp => {
                            //    window.location.replace(urlStore + urlPayApp);
                            //}).catch(err => console.log(err));

                        }).catch(err => console.log(err));
                        */
                    }

                } else {
                    this.$emit("returnData", {
                        addressDetails: this.form.addressDetails,
                        addressSelected: this.form.addressSelected,
                        amountFinal: this.total,
                        methodPay: this.form.methodPay,
                        shippingMethod: this.shippingMethods[0],
                        params
                    })
                }
            } else {
                console.log(this.form.addressSelected, this.form.addressDetails, this.form.name, this.form.phone, this.form.methodPay);
                console.log(this.form.addressSelected !== null && this.form.addressDetails !== "" &&
                this.form.name !== null && this.form.phone.length === 8  && this.form.methodPay !== null );
            }
        },
        setPaymentMethod(){

            if(this.shippingMethods[0].payments)
            {

                console.log("setPaymentMethod(): Payments Delivery : ", this.shippingMethods[0].payments);

                let paymentOnlineWithCard = this.shippingMethods[0].payments.find(element => element.name === 'online_with_card');

                if(paymentOnlineWithCard) {
                    this.form.methodPay = paymentOnlineWithCard.name
                }

                this.form.methodPay = this.shippingMethods[0].payments[0].name || "";

            }
        },
        async deliveryYoVoy() {

            /*
            {
                shopId: Number(this.storeId),
                deliveryPhone: this.deliveryPhone,
                deliveryEmail: this.deliveryEmail,
                deliveryAddress: `${this.shippingData.addressSelected.direccion} - ${this.shippingData.addressDetails}`,
                deliveryName: this.deliveryName,
                deliveryLatitud: this.shippingData.addressSelected.latitude,
                deliveryLongitud: this.shippingData.addressSelected.longitude,
                orderComments: this.shippingData.params.orderComments
            }
             */

            let latitude = 0,
                longitude = 0,
                pickupName = null,
                params = {};
            let pickupEmail = null;
            let cashOnDeliveryTotal = 0;

            if(this.form.methodPay === "cash" && this.total > 0){
                cashOnDeliveryTotal = this.total;
            }

            params.apiToken = "iD4fNt4li3QfuvkL0OdzcG9S51Ao1WNO";

            let datePickup = new Date();
            datePickup.setUTCMinutes(new Date().getMinutes() + 5);

            let dateDelivery = new Date();
            dateDelivery.setUTCMinutes(new Date().getMinutes() + 20 );

            params.pickup = {
                latitude:  Number(this.store.couriers[0]['courierable']['latitude']),
                longitude:  Number(this.store.couriers[0]['courierable']['longitude']),
                email:  this.store.email, //pickupEmail,
                phone: this.store.phone ?? "+50425441717",  // "+50425441717",
                name: this.store.name, //pickupName,
                notes: '',
                reference: '',
                vehicle: 0, // 0 for bike, 1 for car, 2 for light truck
                date: datePickup.toUTCString()
            }

            params.delivery = {
                latitude: Number(this.form.addressSelected.latitude),
                longitude: Number(this.form.addressSelected.longitude),
                email: this.order.ecwid_order_email ?? '',
                phone: this.form.phone, //data.deliveryPhone,
                name: this.form.name, //data.deliveryName,
                notes: `Valor del farestimate obtenido es: L. ${this.amountDelivery} - Comentario del usuario: `, //`${data.orderComments}`,
                reference: `${this.form.addressSelected.name}, ${this.form.addressDetails}`, //`${data.deliveryAddress}`,
                cashOnDelivery: cashOnDeliveryTotal,
                date: dateDelivery.toUTCString()
            }

            //console.table(params);

            return new Promise(async (resolve, reject) => {
                try {
                    const response = await axios.post('https://integrations.yovoyenvios.com/api/delivery/', params);
                    const orderDone = response.data;
                    if (orderDone.success) return resolve(orderDone.success);

                    //return resolve(true); // For testing

                } catch (error) {
                    //console.log(error);

                    return reject(false);
                }
            });

        }
    },
    created(){
        //this.getAddress();
    },
    mounted(){
        //console.log(this.options)

        /*
        * Set methodPay
        */
        this.$nextTick(() => {

           // this.setPaymentMethod()

        })

        /* Set the comments from the order */
        this.orderComments = this.order.ecwid_order_comments;

    }
}
</script>

<style lang="scss">
.deliveryContent {
    text-align: left;
    width: 100%;

    h5 {
        //display: none;
        font-weight: 400;
        letter-spacing: 0.1em;
        font-size: 1rem;
    }

    h5.title {
        font-weight: 600;
        letter-spacing: 0;
        font-size: 0.94rem;
        margin-bottom: 0.7rem;
    }

    h5.subtitle {
        font-weight: 600;
        letter-spacing: 0;
        font-size: 0.85rem;
        margin-bottom: 0.7rem;
    }

    .personalInfoContent {
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: repeat(1, 1fr);

        @media screen and (min-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
        }
    }
    /* p{
        display: none;
    } */

    .optionsOrderTypes {
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: repeat(1, 1fr);
        justify-items: stretch;
        text-align: left;
        margin-bottom: 1.5rem;

        @media screen and (min-width: 1000px) {
            grid-template-columns: repeat(2, 1fr);
        }

        & svg {
            //margin-right: 1rem;
            fill: #BDC7D4 !important;
        }

        & svg g path, polygon {
            fill: #BDC7D4;
        }

        svg > path {
            fill: #BDC7D4;
        }

        input[type="radio"] {
            display: none;

            &:checked + label {
                border: 1.5px solid #0A99FE;
                background-color: #eff6fb;
                //font-weight: 600;
            }
        }

        label {
            padding: 0.5rem 1rem;
            border-radius: 5px;
            border: 1.5px solid #BDC7D4;
            display: flex;
            align-content: center;
            justify-content: space-between;
            align-items: center;
            position: relative;
            cursor: pointer;
            font-size: 0.75rem;
            font-weight: 600;
            width: 100%;
            min-height: 65px;
            -webkit-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;

            &:hover {
                border: 1.5px solid #0A99FE;
                background-color: #eff6fb;
            }

        }
    }

    .optionsOrderTypes input[type="radio"]:checked + label > svg > g > path,
    .optionsOrderTypes input[type="radio"]:checked + label > svg > g > polygon,
    .optionsOrderTypes input[type="radio"]:checked + label > svg > path,
    .optionsOrderTypes input[type="radio"]:checked + label > svg > polygon
    {
        fill: #0A99FE;
    }

    .group-row .vs__selected-options {
        //margin-top: 1rem;
    }

    .deliveryButtons {

        .logos {

        }
    }

    @media screen and (min-width: 768px) {
        //width: 300px;
        padding-top: 4.7rem;

        h5 {
            //display: block;
            //margin-bottom: 0.5rem;
        }

        p {
            padding-bottom: 0.5rem;
            //display: block;
            font-size: 0.75rem;
        }

        .btn {
            //width: 100%;
        }
    }
    @media screen and (min-width: 1024px){
        width: 100%;

        p {
            //padding-bottom: 0.5rem;
            //display: block;
        }
    }
}

.v-select {
    background-color: #F9F9F9;

    &:focus {
        border: 1px solid #0A99FE;
        background-color: #FFF;
        box-shadow: none;
    }
}

.vs__dropdown-toggle,
.vs__dropdown-menu {
    //background-color: #F9F9F9;
    /*
    background: #dfe5fb;
    border: none;
    color: #394066;
    text-transform: lowercase;
    font-variant: small-caps;
    */
    &:focus {
        //border: 1px solid #0A99FE;
        //background-color: #FFF;
    }
}

.group-row {
    padding-bottom: 1.5rem;

    /*
    .label {
        margin-bottom: 0.5rem;
        font-weight: bold;
        font-size: 0.9rem;
    }
    */
}

.text-right {
    text-align: right;
}

/* Skeleton */

.skeletonPrice {
    border-radius: 30px;
    width: 60px;
    height: 14px;
    position: relative;
    float: right;
    overflow: hidden;
}

.skeletonPrice::before {
    content: '';
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(to right, transparent 0%, #EC7357 50%, transparent 100%);
    animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
}

@keyframes load {
    from {
        left: -150px;
    }
    to   {
        left: 100%;
    }
}

h6 {
    font-size: 1rem;
    font-weight: 600;
}

</style>
