<template>
    <div class="contentLocal">
        <h5>ESCRIBE TU NÚMERO DE MESA</h5>
        <p>En la mesa encontrarás un número, escríbelo para que podamos servirte tu pedido.</p>
        
        <div class="group-row">
            <label class="label" for="">Número de mesa</label><br />
            <input
                v-model="numberTable"
                type="text"
                class="input-form"
                placeholder="Número de mesa"
            />
        </div>
        <div class="prices">
            <div class="row">
                <p>Subtotal</p>
                <p style="text-align:right;">L. {{amount}}</p>
            </div>
            <div class="row">
                <p>Total</p>
                <p style="text-align:right;">L. {{amount}}</p>
            </div>
        </div>
        
        <button class="btn" @click="returnData">
            <div v-if="!isValidate">Siguiente</div>
            <div v-else class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </button>
    </div>
</template>

<script>
export default {
    props:{
        amount: String
    },
    data() {
        return {
            numberTable: null,
            isValidate: false
        }
    },
    methods:{
        returnData(){
            console.log('Local');
            if(this.numberTable !== null){
                this.isValidate = true;
                setTimeout(() => this.$emit("returnData", {  numberTable: this.numberTable }), 5000)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .contentLocal{
        text-align: left;
        width: 100%;
        h5{
            display: none;
            font-weight: 300;
            @media screen and (min-width: 768px){
                display: block;
            }
        }
        p{
            display: none;
            @media screen and (min-width: 768px){
                display: block;
            }
        }
        .prices{
            margin:1rem 0; 
            .row{
                
                font-size: .9rem;
                p{
                    display: inline-block;
                    &:last-child{
                        float: right;
                    }
                }
                &:last-child{
                    border-top: solid 1px #ccc;
                    font-size: 1.1rem;
                    font-weight: bold;
                    padding-top:.7rem;
                }
            }
            @media screen and (min-width: 768px){
                display: none;
            }
        }
        .btn{
            margin-top: 1rem;
            @media screen and (min-width: 768px){
                width:10rem;
                margin-top: 5rem;
            }
        }
        @media screen and (min-width: 768px){
            padding-top:3.5rem;
            width: 300px;
        }
        @media screen and (min-width: 1024px){
            width: 400px;
        }
        
    }
</style>