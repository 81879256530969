<template>
    <div class="contentApp">

        <!--
        <transition>
            <div v-show="showScreen === currentPage" class="contentLogosCompany">
                <img src="@/assets/images/LogoVendu.svg" alt="Logo Vendu" />
                <img src="@/assets/images/logo-VPAY.svg" alt="Vendu Pay"/>
                <img src="@/assets/images/icon-LOCK.svg" alt="Pago Seguro"/>
            </div>
        </transition>
        -->

        <div class="wrapper">

            <Address v-if="currentPage === 'address'"
                     :store="store"
                     :neighborhoods="neighborhoods"
                     :order="order"
                     @onAddressSelected="getAddress"
            />

            <!--
            <Sdrp v-else-if="showScreen === 'sdrp'"
                  @onHourSelected="onHour_Selected"
            />
            -->

            <CardForm v-if="currentPage === 'pay' && payOnlineWithCard.paymentable_type === 'App\\Models\\CyberSource'"
                      :order="order"
                      :store="store"
                      :form-data="formData"
                      @input-card-number="updateCardNumber"
                      @input-card-name="updateCardName"
                      @input-card-month="updateCardMonth"
                      @input-card-year="updateCardYear"
                      @input-card-cvv="updateCardCvv"
                      :hour="dataHour"
                      :shippingData="shippingData"
            />

            <cardFormFac v-if="currentPage === 'pay' && payOnlineWithCard.paymentable_type === 'App\\Models\\Fac'"
                      :order="order"
                      :store="store"
                      :form-data="formData"
                      :shippingData="shippingData"
            />

            <cardFormPowertranz v-if="currentPage === 'pay' && payOnlineWithCard.paymentable_type === 'App\\Models\\Powertranz'"
                         :order="order"
                         :store="store"
                         :form-data="formData"
                         :shippingData="shippingData"
            />

        </div>
    </div>
</template>

<script>
import CardForm from '@/components/CardForm'
import Alert from '@/components/Alert'
import Sdrp from '@/components/Sdrp'
import Address from '@/components/Address'
import CardFormFac from '@/components/CardFormFac'
import CardFormPowertranz from '@/components/CardFormPowertranz'

export default {
    name: 'Home',
    components: {
        CardForm,
        Alert,
        Sdrp,
        Address,
        CardFormFac,
        CardFormPowertranz
    },
    props: {
        store: Object,
        order: Object,
        neighborhoods: Object,
        payOnlineWithCard: Object,
    },
    data() {
        return {
            formData: {
                cardName: '',
                cardNumber: '',
                cardMonth: '',
                cardYear: '',
                cardCvv: ''
            },
            showScreen: 'address',
            dataHour: null,
            addressSelected: null,
            shippingData: null,
        }
    },
    methods: {
        updateCardNumber(val) {
        },
        updateCardName(val) {
        },
        updateCardMonth(val) {
        },
        updateCardYear(val) {
        },
        updateCardCvv(val) {
        },
        onHour_Selected(val) {
            this.dataHour = val.hour;
            this.showScreen = val.nextShowScreen;
        },
        getAddress(val) {
            this.showScreen = val.nextShowScreen;
            this.shippingData = val;
        }
    },
    created() {
        if (this.neighborhoods === null) {
            this.shippingData = {
                amountFinal: this.order.total_products,
                params: {
                    billingPerson: this.order.ecwid_order_shipping_person,
                    shippingPerson: this.order.ecwid_order_shipping_person,
                    shippingOption: this.order.ecwid_order_shipping_option
                }
            }
        }

        console.log("shippingData en Home: ", this.shippingData)
    },
    mounted() {
    },
    computed: {
        currentPage() {
            if (this.neighborhoods === null) {
                return 'pay'
            }

            return this.showScreen;
        },

    }
}
</script>

