<template>
    <div>

        <transition>
            <Alert v-show="alertShow" :data="dataAlert" />
        </transition>

        <div class="business-brand">
            <img v-if="store.picture_url"
                :src="store.picture_url"
                 :alt="store.name"
                 style="width: 120px"
            />
        </div>

        <div class="card-form">
            <h2>Pago seguro en línea</h2>
            <form name="formPay" @submit.prevent="Pay">

                <div class="card-form__inner">
                    <div class="card-input">
                        <label for="cardNumber" class="card-input__label label">Número de tarjeta</label>
                        <img
                            alt="Tipo de Tarjeta"
                            class="maskNumberCard"
                            :src="asset(cardTypeLogo)"
                            v-if="cardTypeLogo"
                            :key="cardTypeLogo"
                        />
                        <div class="input-group">

                            <input
                                type="tel"
                                inputmode="numeric"
                                :id="fields.cardNumber"
                                @input="changeNumber"
                                @focus="focusText($event)"
                                class="card-input__input"
                                :value="formData.cardNumber"
                                :maxlength="cardNumberMaxLength"
                                data-card-field
                                autocomplete="cc-number"
                                required
                                placeholder="1234 1234 1234 1234"
                                @keyup="validateCard"
                            />

                            <div class="icon-card-validation">

                                <!-- Card icon -->
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     v-if="this.result.isValid"
                                     fill="none"
                                     viewBox="0 0 24 24"
                                     stroke-width="1.5"
                                     stroke="currentColor"
                                     class="w-6 h-6 text-green-500"
                                >
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>

                                <!-- Check icon -->
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     v-else-if="this.result.isValid === false && formData.cardNumber !== ''"
                                     fill="none"
                                     viewBox="0 0 24 24"
                                     stroke-width="1.5"
                                     stroke="currentColor"
                                     class="w-6 h-6 text-red-500"
                                >
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z" />
                                </svg>
                            </div>

                            <!-- <pre>{{ this.result }}</pre> -->

                        </div>

                    </div>
                    <div class="card-form__group">
                        <div class="card-input">
                            <label for="cardMonth" class="card-input__label label">Fecha de Expiración</label>
                            <input
                                type="tel"
                                inputmode="numeric"
                                class="card-input__input"
                                id="cardMonth"
                                maxlength="5"
                                data-card-field
                                v-mask="'##/##'"
                                autocomplete="cc-exp"
                                v-model="cardExpDate"
                                @focus="focusText($event)"
                                @focusout="getDateExp"
                                @blur="blurTextOpacity($event)"
                                required
                                placeholder="MM/AA"
                            />
                            <div class="input-errors">
                                <div class="error-msg" v-if="cardExpDateErrors">Revisar el mes y año</div>
                            </div>
                            <!--
                            Mes: {{ formData.cardMonth }}
                            Año: {{ formData.cardYear }}
                            -->

                            <!--
                          <eva-icon
                            class="eva_icon"
                            :name="validExpDateIcon.name"
                            animation="pulse"
                            :fill="validExpDateIcon.fill"
                          ></eva-icon>
                          -->
                        </div>
                        <div class="card-input">
                            <label for="cardCvv" class="card-input__label label">CVV / CVC</label>

                            <div class="input-group">

                                <input
                                    type="password"
                                    inputmode="numeric"
                                    class="card-input__input"
                                    v-integer.unsigned
                                    :id="fields.cardCvv"
                                    maxlength="4"
                                    :value="formData.cardCvv"
                                    @input="changeCvv"
                                    data-card-field
                                    autocomplete="off"
                                    @focus="focusText($event)"
                                    @blur="blurTextOpacity($event)"
                                    required
                                    placeholder="CVV"
                                />

                                <button class="icon-eye" @click.prevent="toggleCvv()">

                                    <!-- eye icon -->
                                    <svg xmlns="http://www.w3.org/2000/svg" v-if="cvvShow" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                    </svg>

                                    <svg xmlns="http://www.w3.org/2000/svg" v-else fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="card-input">
                        <label for="cardName" class="card-input__label label">Nombre en la tarjeta</label>
                        <input
                            type="text"
                            :id="fields.cardName"
                            @input="changeName"
                            class="card-input__input"
                            :value="formData.cardName"
                            v-letter-only
                            data-card-field
                            id="cardName"
                            autocomplete="off"
                            @focus="focusText($event)"
                            @blur="blurTextOpacity($event)"
                            required
                            placeholder="Nombre que aparece en tu tarjeta"
                        />
                    </div>
                    <!--
                    <div class="card-form__contentBussineBrand">
                        <img
                            src="../assets/images/LogoPowerChicken.png"
                            alt=""
                        />
                    </div>
                    -->
                    <div class="card-form__row">
                        <button class="card-form__button btn" type="submit">
                            <template v-if="!isPaying">
                                <inline-svg
                                    :src="asset('images/icons/secure.svg')"
                                    aria-label="Pago Seguro en Línea"
                                ></inline-svg>
                                Pagar <span style="padding-left: 4rem;"> {{ store.currency }}  {{ getAmount }}</span>
                            </template>
                            <span v-else class="lds-ellipsis"><span></span><span></span><span></span><span></span></span>
                            <!-- <div  class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div> -->
                        </button>
                    </div>
                    <div class="logos">
                        <img :src="asset('images/visa-mastercard.svg')" alt="Pago con tarjetas Visa y Mastercard" style="max-width: 70px;">
                        <img :src="asset('images/cybersource.svg')" alt="CyberSource">
                        <img :src="asset('images/vendu-pay.svg')" alt="Vendu Pay">
                    </div>
                    <div class="card-form__row text-center content-security">
                        <p>
                            Usted esta realizando una compra con pago seguro en línea de Vendu Pay,
                            <strong> no almacenamos ningún dato de su tarjeta </strong> de crédito o débito, la compra se reflejará
                            en su estado de cuenta con el nombre del comercio donde esta realizando la compra.
                        </p>
                    </div>
                </div>

                <div style="margin-top: 20px">
                    <google-re-captcha-v3
                        v-model="gRecaptchaResponse"
                        ref="captcha"
                        id="payment_id"
                        inline
                        action="payment"
                    ></google-re-captcha-v3>
                </div>

                <!-- <pre>{{ formData }}</pre> -->

            </form>

        </div>

    </div>
    <!--<div class="contentSecurityLogos__isMovil">
          <img src="../assets/images/logosSecurity.png" alt="Logos de seguridad">
      </div>-->
</template>

<script>
import Alert from "@/components/Alert";
import { useToast } from "vue-toastification";
import ToastErrors from "@/components/ToastErrors";
//import axios from "axios";

//import Swal from 'sweetalert2/src/sweetalert2.js';

//import 'sweetalert2/src/sweetalert2.scss';
//import { EvaIcon } from "vue-eva-icons";
import axios from "axios";
import CardValidator from "card-validator"
import GoogleReCaptchaV3 from "@/components/googlerecaptchav3/GoogleReCaptchaV3.vue";

export default {
    name: "CardForm",
    directives: {
        "number-only": {
            mounted: (el) => {
                function checkValue(event) {
                    event.target.value = event.target.value.replace(/[^0-9]/g, "");

                    console.log("charCode", event.key)

                    if (event.key >= 0 && event.key <= 9) {
                        return true;
                    }

                    event.preventDefault();
                }
                el.addEventListener("keydown", checkValue);
            }
        },
        "letter-only": {
            bind(el) {
                function checkValue(event) {
                    if (event.charCode >= 48 && event.charCode <= 57) {
                        event.preventDefault();
                    }
                    return true;
                }
                el.addEventListener("keydown", checkValue);
            },
        },
    },
    setup() {
        // Get toast interface
        const toast = useToast();

        // Make it available inside methods
        return { toast }
    },
    props: {
        order: Object,
        store: Object,
        hour: String,
        backgroundImage: [String, Object],
        randomBackgrounds: {
            type: Boolean,
            default: true,
        },
        shippingData: {
            type: Object,
            required: true
        },
    },
    components: {
        Alert,
        CardValidator,
        GoogleReCaptchaV3,
        ToastErrors
    },
    data() {
        return {
            fields: {
                cardNumber: "v-card-number",
                cardName: "v-card-name",
                cardMonth: "v-card-month",
                cardYear: "v-card-year",
                cardCvv: "v-card-cvv",
            },
            formData: {
                cardName: '',
                cardNumber: '',
                cardNumberNotMask: '',
                cardMonth: '',
                cardYear: '',
                cardCvv: '',
                cardCvvNotMask: ''
            },
            minCardYear: new Date().getFullYear(),
            isCardNumberMasked: true,
            mainCardNumber: this.cardNumber,
            mainCardCvv: this.cardCvv,
            cardNumberMaxLength: 19,
            cardExpDate: "",
            cardExpDateErrors: false,
            amount: this.order.total_products,
            //total: 0,
            storeId: this.order.ecwid_store_id,
            //orderID: this.order.ecwid_order_id,
            orderID: this.order.ecwid_order_number,
            currency: `transaction_${this.order.ecwid_order_number}`,
            tokenf: this.order.ecwid_custom_app_token,
            returnUrl: this.order.ecwid_return_url,
            orderNumber: "",
            addressOrder: "",
            validExpDateIcon: {},
            alertShow: false,
            dataAlert: {
                title: "Título",
                animationPath: "assets/lotties/wait.json",
                description: "Descripción de la alerta",
            },
            deliveryAddress: null,
            deliveryEmail: null,
            deliveryStreet: null,
            deliveryPhone: null,
            deliveryName: null,
            isPaying: false,
            deliveryCity: null,
            deliveryPostalCode: null,
            deliveryCountry: null,
            productsItems: [],
            ipAddress: null,
            sessionIDPromerica: null,
            typeLogoCard: null,
            cvvShow: false,
            gRecaptchaResponse: null
        };
    },
    computed: {
        cardTypeLogo() {
            let number = this.formData.cardNumber;
            let re = new RegExp("^4");
            if (number.match(re) != null) return "img/maskVisa.png";

            re = new RegExp("^(34|37)");
            if (number.match(re) != null) return "img/maskAmex.png";

            re = new RegExp("^5[1-5]");
            if (number.match(re) != null) return "img/maskMastercard.png";

            return ""; // default type
        },
        minCardMonth() {
            if (this.formData.cardYear === this.minCardYear)
                return new Date().getMonth() + 1;
            return 1;
        },
        paymentProvider() {
            return this.$page.props.payOnlineWithCard;
        },
        getAmount() {
            return Number(this.amount).toFixed(2);
        },
        result: function () {
            return CardValidator.number(this.formData.cardNumber);
        },
        appendIcon () {
            return this.result.isValid ? 'mdi-check-underline' : 'mdi-credit-card-outline'
        },
        iconColor() {
            if (this.formData.cardNumber == '') return ''
            return this.result.isValid ? 'green' : 'red'
        }
    },
    watch: {
        cardYear() {
            if (this.formData.cardMonth < this.minCardMonth) {
                this.formData.cardMonth = "";
            }
        },
    },
    created() {
        this.getProductsItems(this.orderID);

        // if(this.shippingData.typeShipping === "Delivery"){

            let that = this;

            axios.get(`https://app.ecwid.com/api/v3/${this.storeId}/orders/${this.orderID}?token=${this.tokenf}`)
                .then(resp => {
                    that.deliveryEmail = resp.data.email;
                })
                .catch(err => console.log(err));

        //}

        //this.deliveryPhone = this.shippingData.params.billingPerson.phone;
        //this.deliveryName = this.shippingData.params.billingPerson.name;
    },
    mounted() {
        //this.maskCardNumber();
        this.maskCardCvv();
        //console.log('shippingData: ', this.shippingData);
        //console.log("returnUrl: ", this.returnUrl)

        this.amount = this.shippingData.amountFinal || this.order.total_products;
        this.sessionIDPromerica = this.$page.props.sessionID;

    },
    methods: {
        cybs_deviceFingerProfiler(merchantID, environment) {
            if (environment.toLowerCase() === "live") {
                var org_id = "k8vif92e";
                //var org_id = "9ozphlqx"; //No flash
            } else {// test
                var org_id = "1snn5n9w";
                //var org_id = "45ssiuz3"; // No flash
            }

            var sessionID = new Date().getTime();

            //One-Pixel Image Code
            /*
          var paragraphTM = document.createElement("p");
          var str = "";
          str =
            "background:url(https://h.online-metrix.net/fp/clear.png?org_id=" +
            org_id +
            "&session_id=" +
            merchantID +
            sessionID +
            "&m=1)";

          paragraphTM.styleSheets = str;

          document.body.appendChild(paragraphTM);

          var img = document.createElement("img");

          str =
                "https://h.online-metrix.net/fp/clear.png?org_id=" +
            org_id +
            "&session_id=" +
            merchantID +
            sessionID +
            "&m=2";

          img.src = str;
          img.alt = "";

            document.body.appendChild(img);

          //Flash Code
          var objectTM = document.createElement("object");

          objectTM.data =
            "https://h.online-metrix.net/fp/fp.swf?org_id=" +
            org_id +
            "&session_id=" +
            merchantID +
            sessionID;

          objectTM.type = "application/x-shockwave-flash";
          objectTM.width = "1";
          objectTM.height = "1";
          objectTM.id = "thm_fp";

            var param = document.createElement("param");
            param.name = "movie";

            param.value =
            "https://h.online-metrix.net/fp/fp.swf?org_id=" +
            org_id +
            "&session_id=" +
            merchantID +
            sessionID;

            objectTM.appendChild(param);

            document.body.appendChild(objectTM);

            */

            //JavaScript Code
            const tmscript = document.createElement("script");

            tmscript.src =
                "https://h.online-metrix.net/fp/tags.js?org_id=" +
                org_id +
                "&session_id=" +
                merchantID +
                sessionID;

            /* console.log(tmscript.src); */
            console.log('tmscript: ', tmscript );

            tmscript.type = "text/javascript";

            //document.body.appendChild(tmscript);

            document.getElementsByTagName('head')[0].appendChild(tmscript);

            this.sessionIDPromerica = sessionID;

            console.log('sessionID: ', sessionID)

            return sessionID;
        },
        toCamel (o) {
            var newO, origKey, newKey, value
            if (o instanceof Array) {
                return o.map(function(value) {
                    if (typeof value === "object") {
                        value = toCamel(value);
                    }
                    return value
                });
            } else {
                newO = {}
                for (origKey in o) {
                    if (o.hasOwnProperty(origKey)) {
                        newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString()
                        value = o[origKey]
                        if (value instanceof Array || (value !== null && value.constructor === Object)) {
                            value = toCamel(value)
                        }
                        newO[newKey] = value
                    }
                }
            }
            return newO
        },
        async getProductsItems() {
            try {
                const res = (await axios.get(`https://app.ecwid.com/api/v3/${this.storeId}/orders/${this.orderID}?token=${this.tokenf}`)).data;
                this.productsItems = res.items.map(product => ({
                    productCode: String(product.productId),
                    productName: product.name,
                    productSku: product.sku,
                    quantity: product.quantity,
                    unitPrice: String(product.price),
                }));

                //console.log("productsItems: ", this.productsItems);

            } catch (error) {
                console.log(error);
            }
        },
        async Pay() {

            let finalNumberCard = this.formData.cardNumber.split(' ').join('');
            //
            let data = {
                paymentProvider: this.paymentProvider,
                store: {
                    uuid: this.store.uuid
                },
                clientReferenceInformation: {
                    code: this.orderID //"TC50171_3"
                },
                paymentInformation: {
                    card: {
                        number: finalNumberCard, //finalNumberCard,
                        expirationMonth: this.formData.cardMonth,
                        expirationYear: this.formData.cardYear,
                        type: this.GetCardType(finalNumberCard),
                        //type: this.getTypeCreditCard(finalNumberCard), // 001 - visa, 002 - , 003 -
                        securityCode: this.formData.cardCvv
                    }
                },
                orderInformation: {
                    amountDetails: {
                        totalAmount: this.getAmount,
                        currency: this.store.currency, // "HNL" //USD //HNL
                    },
                    billTo: {
                        firstName: this.shippingData.params.billingPerson.name, //this.deliveryName,
                        lastName: this.shippingData.params.billingPerson.name, //this.deliveryName,
                        //address1: this.deliveryAddress + this.deliveryStreet,
                        address1: this.shippingData.params.billingPerson.street, //`${this.shippingData.addressSelected.name}, ${this.shippingData.addressDetails}` ,
                        locality: this.shippingData.params.billingPerson.city, // this.deliveryCity || 'Tegucigalpa',
                        //administrativeArea: "FM", // Todo: call this parameter from the database,
                        postalCode: this.shippingData.params.billingPerson.postalCode, //"11101" || this.deliveryPostalCode,
                        country: this.shippingData.params.billingPerson.countryCode, //this.deliveryCountry || 'Honduras',
                        email: this.deliveryEmail,
                        phoneNumber: this.shippingData.params.billingPerson.phone //this.deliveryPhone
                    },
                    lineItems: this.productsItems
                },
                deviceInformation: {
                    fingerprintSessionId: String(this.sessionIDPromerica),
                    /*
                    ipAddress: this.ipAddress,
                    rawData: [
                        {
                            data: "true"
                        }
                    ]
                    */
                },
                'g-recaptcha-response': this.gRecaptchaResponse
                /*
              merchantDefinedInformation: [
                {
                  key: "1",
                  value: "Web"
                },
                {
                  key: "3",
                  value: "Gran Linfa"
                },
                {
                  key: "5",
                  value: finalNumberCard.slice(0,6)
                },
                {
                  key: "7",
                  value: "Invitado"
                },
                {
                  key: "8",
                  value: "10"
                },
                {
                  key: "9",
                  value: "S"
                },
                {
                  key: "10",
                  value: this.deliveryPhone
                },
                {
                  key: "14",
                  value: "No"
                }
              ]
              */
            };
            //console.log(JSON.stringify(data));

            //await this.UpdateOrder();

            //return

            if(
                this.formData.cardNumber !== '' &&
                this.formData.cardName !== '' &&
                this.formData.cardMonth !== '' &&
                this.formData.cardYear  !== '' &&
                this.formData.cardCvv !== '' &&
                this.result.isValid
            )
            {
                // lottie
                this.dataAlert.title = 'Procesando Pago'
                this.dataAlert.animationPath = 'assets/lotties/loader_vendu.json' // 'assets/lotties/sucess.json' || 'assets/lotties/error.json'
                this.dataAlert.description = 'Por favor no cierre esta ventana';
                this.alertShow = true; // muestra/oculta la alerta (true/false)

                /*
                axios.post('/verify', { 'g-recaptcha-response': this.gRecaptchaResponse})
                    .then( response => {
                        this.$refs.captcha.execute(); // every time you submit, the reCAPTCHA token needs to be refreshed
                    }).catch( error => {
                        this.$refs.captcha.execute(); // every time you submit, the reCAPTCHA token needs to be refreshed
                    });

                return false;
                */

                try {
                    const response = (
                        await axios.post(
                            "/api/payment/pay",
                            data
                        )
                    ).data;
                    //).data;

                    console.table("Api payment response: ", response)


                    if(response.status === 'AUTHORIZED' && response.statusCode === 201 ){

                        this.dataAlert.title = 'Pago Autorizado'
                        this.dataAlert.animationPath = 'assets/lotties/success.json'
                        this.dataAlert.description = "";
                        this.alertShow = true; // muestra/oculta la alerta (true/false)
                        await this.UpdateOrder();
                    } else {

                        this.alertShow = false; // muestra/oculta la alerta (true/false)

                        this.toast.error(`Pago Rechazado: ${response.status} ${response.statusCode} ${response.error.reason} ${response.error.message}`, {
                            hideProgressBar: true,
                        })

                        /*
                        Clean the form inputs
                         */
                        this.formClean()

                        /*
                        this.dataAlert.title = 'Pago Rechazado'
                        this.dataAlert.animationPath = 'assets/lotties/error-animate.json' // 'assets/lotties/sucess.json' || 'assets/lotties/error.json'
                        this.dataAlert.description = response.body + '<br> ' + response.message + '<br> ' +
                            "Transacción ID: " +  response.transactionId + '<br> ' +
                            "Estatus: " +  response.status + '<br> ' +
                            "Estatus Code: " +  response.statusCode + '<br> ' +
                            "Error Razón: " +  response.error.reason + '<br> ' +
                            "Error Mensaje: " +  response.error.message + '<br> ' +
                            "Error Detalles: " +  response.error.details
                        ;
                        this.alertShow = true; // muestra/oculta la alerta (true/false)
                        */
                    }
                    //console.log(response);
                } catch (error) {

                    console.log("Api call errors: ", error);

                    this.alertShow = false;

                    this.toast.error({
                            component: ToastErrors,
                            props: {
                                errors: error.response.data.errors
                            },
                        },
                        {
                            hideProgressBar: true,
                            timeout: false
                        })

                    /*
                    this.toast.error(`El sistema encontró errores: ${error.body} ${error.message} `, {
                        hideProgressBar: true,
                    })
                    */

                    /*
                    Clean the form inputs
                     */
                    this.formClean()

                    /*
                    this.dataAlert.title = 'El sistema encontró errores'
                    this.dataAlert.animationPath = 'assets/lotties/error-animate.json' // 'assets/lotties/sucess.json' || 'assets/lotties/error.json'
                    this.dataAlert.description = response.body + '<br> ' + response.message + '<br> ' +
                        "Transacción ID: " +  response.transactionId + '<br> ' +
                        "Estatus: " +  response.status + '<br> ' +
                        "Estatus Code: " +  response.statusCode + '<br> ' +
                        "Error Razón: " +  response.error.reason + '<br> ' +
                        "Error Mensaje: " +  response.error.message + '<br> ' +
                        "Error Detalles: " +  response.error.details
                    ;
                    this.alertShow = true; // muestra/oculta la alerta (true/false)
                    */
                }
            } else {
                this.toast.info("Revise los datos ingresados de su tarjeta", {
                    hideProgressBar: true,
                })
            }
        },
        getDateExp() {
            let arrayDate = this.cardExpDate.split("/");

            console.log("arrayDate: ", arrayDate)

            let months = [],
                years = [],
                validMonth = "",
                validYear = "";
            // months and years
            for (let i = 0; i <= 12; i++) {
                months.push(i < 10 ? "0" + i : "" + i); // agrega los 12 meses
                years.push((this.minCardYear + i).toString()); // agrega 12 años a partir del actual
            }
            validMonth = months.find((month) => month === arrayDate[0]);
            validYear = years.filter((year) => year.substring(2, 4) === arrayDate[1]);

            if (validMonth !== undefined) {
                this.formData.cardMonth = validMonth;
                //this.$emit("input-card-month", this.formData.cardMonth);
            } else
            {
                this.formData.cardMonth = '';
            }

            if (validYear.length > 0) {
                this.formData.cardYear = validYear[0];
                //this.$emit("input-card-year", this.formData.cardYear);
            } else {
                this.formData.cardYear = '';
            }

            if (
                validYear.length > 0 &&
                validMonth !== undefined &&
                validMonth !== "00" &&
                this.cardExpDate !== ""
            ) {
                this.validExpDateIcon = {
                    name: "checkmark-circle-2",
                    fill: "limegreen",
                };
                this.cardExpDateErrors = false;
            } else if (this.cardExpDate === "") {
                this.validExpDateIcon = {};
            } else {
                this.validExpDateIcon = { name: "close-circle", fill: "red" };
                this.cardExpDateErrors = true;
                //this.formData.cardMonth = "";
                //this.formData.cardYear = "";
            }
        },
        changeName(e) {
            // actualiza data del campo del nombre
            this.formData.cardName = e.target.value;
            this.$emit("input-card-name", this.formData.cardName);
        },
        changeNumber(e) {
            //actualiza en data el campo del número de tarjeta
            this.formData.cardNumber = e.target.value;
            let value = this.formData.cardNumber.replace(/\D/g, "");
            // american express, 15 digits
            if (/^3[47]\d{0,13}$/.test(value)) {
                this.formData.cardNumber = value
                    .replace(/(\d{4})/, "$1 ")
                    .replace(/(\d{4}) (\d{6})/, "$1 $2 ");
                this.cardNumberMaxLength = 17;
            } else if (/^\d{0,16}$/.test(value)) {
                // regular cc number, 16 digits
                this.formData.cardNumber = value
                    .replace(/(\d{4})/, "$1 ")
                    .replace(/(\d{4}) (\d{4})/, "$1 $2 ")
                    .replace(/(\d{4}) (\d{4}) (\d{4})/, "$1 $2 $3 ");
                this.cardNumberMaxLength = 19;
            }
            // eslint-disable-next-line eqeqeq
            if (e.inputType == "deleteContentBackward") {
                let lastChar = this.formData.cardNumber.substring(
                    this.formData.cardNumber.length,
                    this.formData.cardNumber.length - 1
                );
                // eslint-disable-next-line eqeqeq
                if (lastChar == " ") {
                    this.formData.cardNumber = this.formData.cardNumber.substring(
                        0,
                        this.formData.cardNumber.length - 1
                    );
                }
            }
            this.$emit("input-card-number", this.formData.cardNumber);
        },
        changeMonth() {
            this.$emit("input-card-month", this.formData.cardMonth);
        },
        changeYear() {
            this.$emit("input-card-year", this.formData.cardYear);
        },
        changeCvv(e) {
            this.formData.cardCvv = e.target.value;
            this.$emit("input-card-cvv", this.formData.cardCvv);
        },
        validateCard(){

            let cardValidation = CardValidator.number(this.formData.cardNumber);

            cardValidation.isValid

        },
        identifyCard: function() {
            let niceType = this.result?.card?.niceType
            let filter = '*'
            if (niceType) {
                let type = niceType.toLowerCase().replace(/\s/g, '')
                filter = `#${type}`
            }
            //this.iso.arrange({ filter })
        },
        invalidCard() {
            let number = this.formData.cardNumberNotMask.replace(/ /g, "");
            var sum = 0;
            for (var i = 0; i < number.length; i++) {
                var intVal = parseInt(number.substr(i, 1));
                if (i % 2 === 0) {
                    intVal *= 2;
                    if (intVal > 9) {
                        intVal = 1 + (intVal % 10);
                    }
                }
                sum += intVal;
            }
            if (sum % 10 !== 0) {
                alert("Error");
            }
        },
        blurCardNumber(event) {
            if (this.isCardNumberMasked) {
                this.maskCardNumber();
            }
            event.target.style.color = "#9EA0A0";
        },
        blurTextOpacity(event) {
            event.target.style.color = "#9EA0A0";
        },
        focusText(event) {
            event.target.style.color = "#444950";
        },
        maskCardNumber() {
            // enmascara el número de la tarjeta
            this.formData.cardNumberNotMask = this.formData.cardNumber;
            this.mainCardNumber = this.formData.cardNumber;
            let arr = this.formData.cardNumber.split("");
            arr.forEach((element, index) => {
                if (index > 4 && index < 14 && element.trim() !== "") {
                    arr[index] = "·";
                }
            });
            this.formData.cardNumber = arr.join("");
        },
        unMaskCardNumber() {
            this.formData.cardNumber = this.mainCardNumber;
        },
        focusCardNumber() {
            //this.unMaskCardNumber()
        },
        maskCardCvv() {
            // enmascara el numero de cvv/cvc
            //this.formData.cardNumberNotMask = this.formData.cardNumber
            this.cardCvvNotMask = this.formData.cardCvv;
            this.mainCardCvv = this.formData.cardCvv;
            let arr = this.formData.cardCvv.split("");
            arr.forEach((element, index) => {
                if (index > 1 && index < 2 && element.trim() !== "") {
                    arr[index] = "·";
                }
            });

            this.formData.cardCvv = arr.join("");
            //this.formData.cardNumber = arr.join('')
        },
        toggleMask() {
            this.isCardNumberMasked = !this.isCardNumberMasked;
            if (this.isCardNumberMasked) {
                this.maskCardNumber();
            } else {
                this.unMaskCardNumber();
            }
        },
        toggleCvv() {
            const card_cvv = document.querySelector('#v-card-cvv');
            // toggle the type attribute
            const type = card_cvv.getAttribute('type') === 'password' ? 'text' : 'password';
            card_cvv.setAttribute('type', type);
            // toggle the eye slash icon
            this.cvvShow = !this.cvvShow;

        },
        formClean() {
            //this.amount = 0;
            this.formData.cardNumberNotMask = "";
            this.formData.cardNumber = "";
            this.formData.cardName = "";
            this.formData.cardCvv = "";
            this.formData.cardMonth = "";
            this.formData.cardYear = "";
            this.cardExpDate = ""
        },
        setEndpoit(finalYear, finalAmount, Card2) {
            // .log('ENDPOINT')
            //const url = 'https://vendu.app/payfac/wsAuthorizeSOAP.php'
            const url = 'https://vendu-app.azurewebsites.net/wsAuthorizeSOAP.php'
            const PROXY_URL = 'https://cors-anywhere.herokuapp.com/'
            var transactions = this.currency
            axios.post(PROXY_URL+url, { // let facId = '88801848', facPassword = 'vO54Uq2o', facEnv = 0;
                password: '',
                facId: '',
                acquirerId: '',
                amount: finalAmount,
                currency: '340',
                CardCVV2: this.formData.cardCvv,
                CardExpiryDate: this.formData.cardMonth + finalYear,
                CardNumber: Card2,
                IssueNumber: '',
                StartDate: '',
                produccion: 1
            }).then(response => {
                console.log(response);
                // Respuesta del servidor
                var responseCode = response.data.ReasonCode
                var responseText = response.data.ReasonCodeDescription
                // console.log(transactions)
                /* console.log(responseCode," - "+responseText); */

                if (responseCode === '1') {
                    this.dataAlert.title = 'Pago exitoso'
                    this.dataAlert.animationPath = 'assets/lotties/sucess.json' // 'assets/lotties/sucess.json' || 'assets/lotties/error.json'
                    this.dataAlert.urlDescriptionImg = 'assets/lotties/transaccion-exitosa.svg'
                    this.alertShow = true;
                    this.UpdateOrder(transactions);
                } else {
                    //this.UpdateOrder(transactions);
                    this.dataAlert.title = 'Pago no completado'
                    this.dataAlert.animationPath = 'assets/lotties/error.json' // 'assets/lotties/sucess.json' || 'assets/lotties/error.json'
                    this.dataAlert.urlDescriptionImg = 'assets/lotties/pago-rechazado.svg'
                    this.alertShow = true;
                    //console.log(responseText)
                    // window.location.replace('http://pay.powerchicken.hn/anim/error/errorp.php')
                }
            }).catch(e => {
                this.dataAlert.title = 'Pago no completado'
                this.dataAlert.animationPath = 'assets/lotties/error.json' // 'assets/lotties/sucess.json' || 'assets/lotties/error.json'
                this.dataAlert.urlDescriptionImg = 'assets/lotties/pago-rechazado.svg'
                this.alertShow = true;
                //console.log(e)
            })
        },
        async UpdateOrder() {

            //let rshop = transactions
            // var myJSON = { "paymentStatus": "PAID", "referenceTransactionId": rshop};
            let params = {
                'paymentStatus': 'PAID',
                //"orderComments": this.hour
                // "referenceTransactionId": rshop
                "externalTransactionId": this.$page.props.sessionID
            }

            //if(this.shippingData.typeShipping === "Delivery"){
            params = Object.assign({'paymentStatus': 'PAID'}, this.shippingData.params);
            //}

            let url = `https://app.ecwid.com/api/v3/${this.storeId}/orders/transaction_${this.orderID}?token=${this.tokenf}`;

            //var xhr = new XMLHttpRequest()
            // console.log(params)
            /*
            xhr.addEventListener('readystatechange', function() {
                if (this.readyState === this.DONE) {
                    //console.log(this.responseText)
                }
            })
            */
            // var jshop = this.orderID
            var urlProg = "https://app.ecwid.com/api/v3/31436259/orders/"
            var urlVilla = "https://app.ecwid.com/api/v3/40993297/orders/"
            var urlChol = "https://app.ecwid.com/api/v3/32268563/orders/"
            var urlSPS = "https://app.ecwid.com/api/v3/31552060/orders/"
            var urlSPSCity = "https://app.ecwid.com/api/v3/43188072/orders/" //
            var urlSPSLima = "https://app.ecwid.com/api/v3/43195065/orders/" //
            var urlTEG = "https://app.ecwid.com/api/v3/44727221/orders/" //
            let urlPreTegu = "https://app.ecwid.com/api/v3/58671338/orders/";
            let urlPreCho = "https://app.ecwid.com/api/v3/58566203/orders/";
            let urlPreVillaN = "https://app.ecwid.com/api/v3/58673318/orders/";
            let urlPreProg = "https://app.ecwid.com/api/v3/58673319/orders/";
            let urlPreCityMall = "https://app.ecwid.com/api/v3/55560374/orders/";
            let urlPreLima = "https://app.ecwid.com/api/v3/55491231/orders/";
            let urlPrePalenque = "https://app.ecwid.com/api/v3/55436462/orders/";
            var urlPreTeguApp = "&clientId=custom-app-58671338-1";
            var urlPreChoApp = "&clientId=custom-app-58566203-1";
            var urlPreVillaNApp = "&clientId=custom-app-58673318-1";
            var urlPreProgApp = "&clientId=custom-app-58673319-1";
            var urlPreCityMallApp = "&clientId=custom-app-55560374-1";
            var urlPreLimaApp = "&clientId=custom-app-55491231-1";
            var urlPrePalenqueApp = "&clientId=custom-app-55436462-1";
            var urlTEGAPP = "&clientId=custom-app-44727221-1"
            var urlProgApp = "&clientId=custom-app-31436259-1"
            var urlVillaApp = "&clientId=custom-app-40993297-1"
            var urlSPSApp = "&clientId=custom-app-31552060-1"
            var urlSPSAppCity = "&clientId=custom-app-43188072-1"
            var urlSPSAppLima = "&clientId=custom-app-43195065-1"
            var urlSPSAppChol = "&clientId=custom-app-32268563-1"
            var urlPay = "https://app.ecwid.com/api/v3/55436462/orders/"
            var urlPayApp = "&clientId=custom-app-55436462-1"
            if (this.storeId === "40993297") {
                urlPay = urlVilla
                urlPayApp = urlVillaApp
            }

            if (this.storeId === "31436259") {
                urlPay = urlProg
                urlPayApp = urlProgApp
            }

            if (this.storeId === "31552060") {
                urlPay = urlSPS
                urlPayApp = urlSPSApp
            }

            if (this.storeId === "43188072") {
                urlPay = urlSPSCity
                urlPayApp = urlSPSAppCity
            }

            if (this.storeId === "43195065") {
                urlPay = urlSPSLima
                urlPayApp = urlSPSAppLima
            }

            if (this.storeId === "32268563") {
                urlPay = urlChol
                urlPayApp = urlSPSAppChol
            }

            if (this.storeId === "44727221") {
                urlPay = urlTEG
                urlPayApp = urlTEGAPP
            }

            if (this.storeId === "58671338") {//pretegu
                urlPay = urlPreTegu
                urlPayApp = urlPreTeguApp
            }

            if (this.storeId === "58566203") {//precholoma
                urlPay = urlPreCho
                urlPayApp = urlPreChoApp
            }
            if (this.storeId === "58673318") {//villanueva
                urlPay = urlPreVillaN
                urlPayApp = urlPreVillaNApp
            }
            if (this.storeId === "58673319") {//preprogreso
                urlPay = urlPreProg
                urlPayApp = urlPreProgApp
            }
            if (this.storeId === "55560374") {//precitymall
                urlPay = urlPreCityMall
                urlPayApp = urlPreCityMallApp
            }
            if (this.storeId === "55491231") {//prelalima
                urlPay = urlPreLima
                urlPayApp = urlPreLimaApp
            }
            if (this.storeId === "55436462") {//prepalenque
                urlPay = urlPrePalenque
                urlPayApp = urlPrePalenqueApp
            }

            let tok = this.tokenf
            /*
            xhr.open('PUT', url)
            xhr.setRequestHeader('content-type', 'application/json')
            // console.log("URL FINAL: "+ "https://app.ecwid.com/api/v3/32268563/orders/$shopID?token=secret_mzvcQyumPLZuJU7fyagaAEpY71DRNUtc")
            xhr.send(JSON.stringify(params))
            */

            let storeReturnUrl = this.returnUrl;

            try {
                const orderUpdatePaymentStatus = await axios.put(url, params);
                //let shippingPerson = Object.assign({'shippingPerson': {}}, params.shippingPerson);
                const orderUpdateShippingPerson = await axios.put(url, params);
                //console.log("orderUpdateShippingPerson: ", orderUpdateShippingPerson)
                window.location.replace(storeReturnUrl);
            }
            catch(errors){
                console.log("Errors cardForm update order: ", errors)
            }

            /*
            axios.put(url, params).then(res => {

                console.log("Url de redireccionamiento: ", storeReturnUrl)

                console.log("Respuesta de Ecwid: ", res)

                //window.location.replace(storeReturnUrl)


            }).catch(err => console.log(err));
            */


            // var finalUrl = url + '&clientId=custom-app-32268563-2'
            // console.log(finalUrl)
            /**
             var param = {
                      'returnURL': url
                    }
             **/
                // this.openPost('https://pay.powerchicken.hn/anim/exito.php', 'width=1000, height=600, left=100, top=100, resizable=yes, scrollbars=yes', 'NewFile', param)


        },
        GetCardType(number) {
            // visa
            //let re = new RegExp('^4')
            let re = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$')
            if (number.match(re) != null) { return { name: 'Visa', value: '001' } }

            // Todo, check that mastercard can read card that start with 5 y 2
            // Mastercard
            //re = new RegExp('^5[1-5]')
            //re = new RegExp('^5[1-5][0-9]{14}|^(222[1-9]|22[3-9]\\d|2[3-6]\\d{2}|27[0-1]\\d|2720)[0-9]{12}$')
            re = new RegExp('^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$')
            if (number.match(re) != null) { return { name: 'Mastercard', value: '002' } }

            // AMEX
            re = new RegExp('^3[47]')
            if (number.match(re) != null) { return { name: 'Amex', value: '003' } }

            // Discover
            re = new RegExp('^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)')
            if (number.match(re) != null) { return { name: 'Discover', value: '004' } }

            // Diners
            re = new RegExp('^36')
            if (number.match(re) != null) { return { name: 'Diners', value: '005' } }

            // Diners - Carte Blanche
            re = new RegExp('^30[0-5]')
            if (number.match(re) != null) { return { name: 'Diners - Carte Blanche', value: '006' } }

            // JCB
            re = new RegExp('^35(2[89]|[3-8][0-9])')
            if (number.match(re) != null) { return { name: 'JCB', value: '007' } }

            // Visa Electron
            re = new RegExp('^(4026|417500|4508|4844|491(3|7))')
            if (number.match(re) != null) { return { name: 'Visa Electron', value: '033' } }

            return '';
        }
    },
};
</script>

<style>
.spinner {
    margin:0 auto;
    width: 70px;
    text-align: center;
}

h2 {
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.input-group {
    position: relative;
}

.input-group .icon-eye {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    /*cursor: pointer;*/
}

.input-group .icon-card-validation {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
}


.spinner > div {
    width: 18px;
    height: 18px;
    background-color: #fff;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% { -webkit-transform: scale(0) }
    40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    } 40% {
          -webkit-transform: scale(1.0);
          transform: scale(1.0);
      }
}

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 10px;
}
.lds-ellipsis span {
    position: absolute;
    top: 0;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis span:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis span:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis span:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis span:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}

</style>
