<template>
    <div class="addressContent">
        <div class="addressContent__suburb">
            <img style="width: 120px"
                 class="logo"
                 v-if="store.picture_url"
                 :src="store.picture_url"
                 :alt="store.name"
            />
            <!-- <h5>MÉTODOS DE ENTREGA</h5> -->
            <p class="subtitleBig">Selecciona como deseas recibir tu pedido</p>
            <div class="optionsOrderTypes">
                <span v-for="shippingType of store.couriers" :key="shippingType.id">
                    <input
                        class="radio"
                        type="radio"
                        name="orderTypeSelected"
                        :id="shippingType.name"
                        :value="shippingType.name"
                        v-model="orderTypeSelected"
                        @change="onChangeReceiveOption(shippingType.name)"
                    />
                    <label :for="shippingType.name">
                        {{ shippingType.name }}
                        <inline-svg
                            v-if="shippingType.icon_url"
                            :src="shippingType.icon_url"
                            width="25"
                            height="25"
                            :aria-label="shippingType.name"
                        ></inline-svg>
                    </label>
                </span>

                <!--
                <span>
                    <input
                        class="radio"
                        type="radio"
                        name="orderTypeSelected"
                        id="Delivery"
                        value="Delivery"
                        v-model="orderTypeSelected"
                        @change="onChangeReceiveOption('Delivery')"
                    />
                    <label for="Delivery">Delivery</label>
                </span>
                <span>
                    <input
                        class="radio"
                        type="radio"
                        name="orderTypeSelected"
                        id="Pickup"
                        value="Pickup"
                        v-model="orderTypeSelected"
                        @change="onChangeReceiveOption('Pickup')"
                    />
                    <label for="Pickup">Pickup</label>
                </span>
                -->
                <!-- <span >
                    <input
                        class="radio"
                        type="radio"
                        name="orderTypeSelected"
                        id="Local"
                        value="Local"
                        v-model="orderTypeSelected"
                        @change="onChangeReceiveOption('Local')"
                    />
                    <label for="Local">Servir en la mesa</label>
                </span> -->
            </div>
            <div class="infoOrderType">
                <h5 class="subtitle">Instrucciones para el {{orderTypeCurrent.type || ""}}</h5>
                <p>{{orderTypeCurrent.description || ""}}</p>
            </div>
            <div v-if="orderTypeSelected === 'Delivery'" class="infoDelivery">
                <h5>Precio del Delivery</h5>
                <table>
                <tr class="item">
                    <td>Total de compra</td>
                    <td class="text-right">L. {{ getAmount }}</td>
                </tr>
                <tr style="color: #00C3FF; font-weight: 600; font-size: 0.88rem">
                    <td>+ Delivery</td>
                    <td v-if="loadingFareEstimate" class="text-right">
                        <div class="skeletonPrice"></div>
                    </td>
                    <td v-else class="text-right">L. {{ amountDelivery }}</td>
                </tr>
                <!-- <tfoot style="border: 1px solid">
                    <tr class="tfoot">
                    <td>Total</td>
                    <td class="text-right">L. {{ getTotal.toFixed(2) }}</td>
                    </tr>
                </tfoot> -->
                </table>
                <div class="tfoot">
                    <p>Total a pagar</p>
                    <p class="text-right total">L. {{ getTotal }}</p>
                </div>
            </div>
        </div>
        <div class="addressContent__typeReceiveOrder">
            <Delivery v-if="orderTypeSelected === 'Delivery'"
                :order="order"
                :options="{amount, storeId, orderID, tokenf, returnUrl, shopName: getCurrentCity}"
                :amount="amount"
                :shopDetails="geoPositionCurrentStore"
                :neighborhoods="neighborhoods"
                :shippingMethods="shippingMethods"
                :store="store"
                @returnData="getDataOrder"
                @returnFareEstimate="getFareEstimate"
            />
            <Pickup v-else-if="orderTypeSelected === 'Pickup'"
                :order="order"
                :store="store"
                :amount="amount"
                :storeId="storeId"
                :orderId="orderID"
                :token="tokenf"
                :returnUrl="returnUrl"
                :shopName="getCurrentCity"
                :shippingMethods="shippingMethods"
                @returnData="getDataOrder"
            />
            <Local v-else-if="orderTypeSelected === 'Local'"
                :amount="amount"
                @returnData="getDataOrder"
            />
        </div>
    </div>
</template>
<script>

import Delivery from './Delivery';
import Pickup from './Pickup';
import Local from './Local';

export default {
    components:{
        Delivery,
        Pickup,
        Local,
    },
    props: {
        store: Object,
        neighborhoods: Object,
        order: Object,
    },
    data() {
        return {
            amount: this.order.total_products,
            total: 0,
            storeId: this.order.ecwid_store_id,
            //orderID: this.order.ecwid_order_id,
            orderID: this.order.ecwid_order_number,
            currency: `transaction_${this.order.ecwid_order_id}`,
            tokenf: this.order.ecwid_custom_app_token,
            returnUrl: this.order.ecwid_return_url,
            orderTypeSelected: null,
            ordersTypeDetail: [
                {
                    type: "Delivery",
                    description: "Ingresa tu orden y nosotros la enviaremos a la dirección que has especificado dentro de nuestras zonas de envío, paga la orden en línea para agilizar el tiempo de entrega."
                },
                {
                    type: "Pickup",
                    description: "Ingresa tu orden y pasa por el restaurante recogiendo tu pedido, puedes pagar en línea o contra entrega en la sucursal."
                },
                {
                    type: "Local",
                    description: "Busca en la lista de colonias y barrios disponibles para entrega y escribe los detalles que puedan ayudar a nuestro delivery a llegar a tu dirección."
                }
            ],
            orderTypeCurrent: {   type: "Delivery", description: "" },
            shippingMethods: {},
            geoPositionCurrentStore:{},
            loadingFareEstimate: false,
            amountDelivery: 0,
            currentCity:{
                "31552060": {city: "San Pedro Sula", postalCode: "21101"},
                "43188072": {city: "San Pedro Sula", postalCode: "21101"},
                "43195065": {city: "San Pedro Sula", postalCode: "21101"},
                "40993297": {city: "Villanueva", postalCode: "21000"},
                "44727221": {city: "Tegucigalpa", postalCode: "11101"},
                "32268563": {city: "Choloma", postalCode: "21112"},
                "37323130": {city: "Tegucigalpa", postalCode: "11101"}
            }
        }
    },
    computed:{
        getAmount(){
            return Number(this.amount).toFixed(2);
        },
        getTotal(){
            this.total = Number(this.amount) + Number(this.amountDelivery);
            return this.total ? this.total.toFixed(2) : 0;
        },
        getCurrentCity(){
            return {
                city: this.$page.props.store.city.name,
                postalCode:  this.$page.props.store.city.city_code
            };
        }
    },
    methods: {
        getFareEstimate(val){
            //console.log("getFareEstimate", val);
            this.loadingFareEstimate = val.loadingFareEstimate;
            this.amountDelivery = val.amountDelivery;
            this.total = val.total;
        },
        onChangeReceiveOption(shippingName) {
            this.orderTypeCurrent = this.ordersTypeDetail.filter(element => element.type === shippingName)[0];
            this.shippingMethods = this.store.couriers.filter(element => element.name === shippingName);
        },
        getDataInit() {
            /*
            const querystring = window.location.search;
            const params = new URLSearchParams(querystring);
            this.returnUrl = params.get("url");
            this.amount = params.get("amount");
            this.orderID = params.get("orderID");
            this.storeId = params.get("storeID");
            this.currency = params.get("refID");
            this.tokenf = params.get("token");
            */
            this.orderTypeSelected = "Delivery";
            this.orderTypeCurrent = this.ordersTypeDetail.filter(o => o.type === this.orderTypeSelected)[0];
            //this.orderTypeCurrent = this.ordersTypeDetail.filter(o => o.type === "Delivery")[0];
            /* if (this.storeId === "40993297") {
                this.nameShop = "Villanueva";
                this.webShop = "villanueva";
                this.geoPositionCurrentStore = {
                    latitude: 15.3212193,
                    longitude: -87.9941559
                }
            }

            if (this.storeId === "31436259") {
                this.nameShop = "Progreso";
                this.webShop = "progreso";
                this.geoPositionCurrentStore = {
                    latitude: 15.4035883,
                    longitude: -87.8140033
                }
            } */

            if (this.storeId === "31552060") {
                this.nameShop = "SPS Palenque";
                this.webShop = "palenque";
                this.geoPositionCurrentStore = {
                    latitude: 15.5386298,
                    longitude: -88.0202629
                }
            }

            if (this.storeId === "55436462") {
                this.nameShop = "SPS Palenque";
                this.webShop = "prepalenque";
                this.geoPositionCurrentStore = {
                    latitude: 15.5386298,
                    longitude: -88.0202629
                }
            }

            if (this.storeId === "43188072") {
                this.nameShop = "SPS CityMall";
                this.webShop = "citymall";
                this.geoPositionCurrentStore = {
                    latitude: 15.4982159,
                    longitude: -88.0405614
                }
            }

            if (this.storeId === "43195065") {
                this.nameShop = "SPS La Lima";
                this.webShop = "lalima";
                this.geoPositionCurrentStore = {
                    latitude: 15.503099776940127,
                    longitude: -88.00760748617391
                }
            }

            if (this.storeId === "40993297") {
                this.nameShop = "Villanueva";
                this.webShop = "villanueva";
                this.geoPositionCurrentStore = {
                    latitude: 15.3209813,
                    longitude: -87.9918868
                }
            }


            if(this.storeId === null){
                this.storeId = "55436462";
                this.nameShop = "PruebaLocal";
                this.webShop = "PruebaLOcal";
                this.geoPositionCurrentStore = {
                    latitude: 15.5386298,
                    longitude: -88.0202629
                }
                console.log('Este es un store de prueba');
            }
        },
        getDataOrder(data){
            let params = {};
            //this.cssCustom();
            params.nextShowScreen = "pay";
            params.typeShipping = this.orderTypeSelected;
            params = Object.assign(params, data);
            //console.log(params);
            this.$emit("onAddressSelected", params);
        },
        cssCustom(){
            let bigScreens = window.matchMedia("(min-width: 768px)");
            document.querySelector('.contentApp').style.flexDirection = 'column';
            document.querySelector('.contentLogosCompany ').style.width = '100%';
            if(bigScreens.matches){
                document.querySelector('.wrapper').style.height = 'auto';
                document.querySelector('.wrapper').style.overflow = 'hidden';
            }else{
                document.querySelector('.wrapper').style.height = '100vh';
            }
        }

    },
    created(){
        this.getDataInit();

        this.shippingMethods = this.store.couriers.filter(element => element.name === 'Delivery');

    }
}
</script>

<style lang="scss">
    .addressContent {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        justify-items: center;
        align-items: center;
        padding: 3rem 2.5rem;
        font-family: 'Montserrat', sans-serif;
        color: #000000;
        max-width: 1166px;

        @media screen and (min-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
            padding: 0 2rem;
            grid-gap: 5rem;
        }
        &__suburb {
            text-align: left;

            .logo {
                margin-bottom: 2rem;
            }

            h5 {
                font-weight: 400;
                letter-spacing: 0.1em;
                font-size: 1rem;
            }

            p{
                font-size: 1.8rem;
            }

            .subtitle {
                font-style: normal;
                font-weight: 600;
                font-size: 1.1rem;
                margin-bottom: 1rem;
                letter-spacing: 0;

            }

            .subtitleBig {
                font-style: normal;
                font-weight: 500;
                font-size: 2.2rem;
                line-height: 2.5rem;
                margin: 1rem 0 2rem 0;
            }

            .optionsOrderTypes {
                display: grid;
                grid-gap: 1rem;
                grid-template-columns: repeat(1, 1fr);
                justify-items: stretch;
                text-align: center;
                margin-bottom: 2rem;

                @media screen and (min-width: 1000px) {
                    grid-template-columns: repeat(2, 1fr);
                }

                svg > g > path, polygon {
                    fill: #BDC7D4;
                }

                svg > path {
                    fill: #BDC7D4;
                }
            }

            .optionsOrderTypes input[type="radio"] {
                display: none;
            }
            .optionsOrderTypes label {
                padding: 0.6rem 1rem;
                border-radius: 5px;
                border: 1.5px solid #BDC7D4;
                display: flex;
                align-content: center;
                justify-content: space-between;
                align-items: center;
                position: relative;
                cursor: pointer;
                font-size: 0.8rem;
                font-weight: 600;
                width: 100%;
                -webkit-transition: all 0.3s ease-in-out;
                transition: all 0.3s ease-in-out;
            }

            .optionsOrderTypes label:hover {
                border: 1.5px solid #0A99FE;
                background-color: #eff6fb;
            }

            .optionsOrderTypes input[type="radio"]:checked + label {
                border: 1.5px solid #0A99FE;
                background-color: #eff6fb;
                //font-weight: 600;
            }

            .optionsOrderTypes input[type="radio"]:checked + label > svg > g > path,
            .optionsOrderTypes input[type="radio"]:checked + label > svg > g > polygon {
                fill: #0A99FE;
            }

            .infoOrderType {
                display: none;
                margin-bottom: 3rem;
                h5 {
                    font-size: 0.95rem;
                    font-weight: 600;
                    margin-bottom: 0.5rem;
                }
                p {
                    font-size: 0.75rem;
                }
                @media screen and (min-width: 768px) {
                    display: block;
                }
            }
            .infoDelivery {
                display: none;

                .item {
                    color: #9EA7B1;
                    font-size: 0.88rem;
                }

                h5 {
                  font-weight: 600;
                  letter-spacing: 0;
                  font-size: 0.95rem;
                  margin-bottom: 0.5rem;
                }

                table{
                    width: 100%;

                    tr td{
                        padding-bottom: 0.4rem;

                    }
                }
                .tfoot {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    font-weight: 600;
                    //font-size: 0.4rem;
                    border-top: 0.5px solid #BDC7D4;
                    margin-top: 1rem;

                    p {
                        margin: 0;
                        padding: 1.5rem 0;
                        font-size: 0.88rem;
                    }

                    .total {
                        font-size: 1.25rem;
                    }
                }
                @media screen and (min-width: 768px){
                    display: block;
                }
            }
            @media screen and (min-width: 768px){
                p{
                    font-size: 2.2rem;
                }
                .group-row .input-form{
                    padding: 1.5rem 1.8rem;
                }
            }
            @media screen and (min-width: 1025px){
                width: 500px;
            }
        }
        &__typeReceiveOrder{
            width: 100%;
            padding-top:2rem;
            @media screen and (min-width: 768px) {
                padding-top:0;
                width: 100%;
            }
        }

    }

    .group-row{
        padding-bottom: 1.5rem;
        .label{
            margin-bottom: .5rem;
            font-weight: bold;
        }
    }

    .text-right{
        text-align: right;
    }

</style>
