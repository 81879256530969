<template>
    <main class="addressContent">
        <div class="addressContent__suburb">
            <img
            style="width: 100px"
            src=""
            alt="logo"
            />
            <h5>COLONIAS DISPONIBLES DE ENTREGA</h5>
            <p>Selecciona el barrio o colonia donde quieres recibir tu pedido</p>

            <div class="group-row">
                <label class="label" for="">Colonia o barrio</label>
                <vSelect
                    class="selectAddress"
                    v-model="addressSelected"
                    :options="addressOptions"
                    @search="fetchOptions"
                    @input="coloniaSeleccionada"
                    label="nombre"
                    placeholder="Busca tu barrio o colonia" >
                    <div slot="no-options">No hay coincidencias con ese barrio o colonia.</div>
                </vSelect>
            </div>
            <div class="group-row">
                <label class="label" for="">Detalle de la dirección</label><br>
                <input type="text" class="input-form" placeholder="Calle, avenida, número de casa" />
            </div>
        </div>
        <div class="addressContent__delivery">
            <h5>COSTO DEL DELIVERY</h5>
            <table>
                <tr>
                    <td>Sub Total</td>
                    <td class="text-right">L. 560.00</td>
                </tr>
                <tr style="color: red">
                    <td>Precio de Delivery</td>
                    <td class="text-right">L. {{amountDelivery.toFixed(2)}}</td>
                </tr>
                <tfoot>
                    <tr class="tfoot">
                    <td>Total</td>
                    <td class="text-right">L. 640.00</td>
                    </tr>
                </tfoot>
            </table>
            <p>Nuestro delivery pronto se comunicará contigo para entregar tu pedido.</p>
            <button class="btnAddress">Siguiente</button>
        </div>
    </main>
</template>
<script>

    import vSelect from 'vue-select';
    import 'vue-select/dist/vue-select.css';

    import axios from 'axios';

export default {
    components:{
        vSelect,
    },
    data(){
        return{
            addressSelected : '',
            addressOptions: [],
            amountDelivery: 0
        }
    },
    methods: {
        fetchOptions(search){
            //loading(true)
                     console.log("ENTO")
            if(search.length >= 3){
                console.log("ENTO")
                fetch(`https://symmetrisk-pay.azurewebsites.net/api/Colonia/searchgranlinfa?direccion=${search}`)
                .then(res => {
                    res.json().then(json => {
                        this.addressOptions = json.data;
                        console.log(this.addressOptions)
                        //loading(false)
                    });
                });
            }
        },
        async getFareEstimate (data) {
            try {
                const res = await axios.post('https://integrations.yovoyenvios.com/api/delivery/fare-estimate',data,data);
                const dato = res.data;
                return dato;
            } catch (error) {
                return 70;
            }
        },
        coloniaSeleccionada(){
            //console.log(this.addressSelected);
            const params = {
                pickup: {
                    latitude : 15.4982159,
                    longitude : -88.0536935
                },
                delivery: {
                    latitude: 15.502866, //this.addressSelected.Latitud,
                    longitude: -87.986226 //this.addressSelected.Longitud
                },
                vechicle: 0,
                apiToken: "iD4fNt4li3QfuvkL0OdzcG9S51Ao1WNO"
            }
            console.log(params);
           /// this.getFareEstimate(params).then(resp => {this.amountDelivery = resp.fare; console.log(resp)}).catch(err => console.log(err))
        }
    }

}
</script>

<style lang="scss" scoped>
    .addressContent{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;
        margin: 0 3rem;
        font-family: 'Montserrat', sans-serif;
        color:#333;

        &__suburb{
            text-align: left;
            h5{
                font-weight: 300;
            }
            p{
                font-size: 1.8rem;
            }
            .group-row .vs__selected-options{
                margin-top: 1rem;
            }
            .group-row .input-form{
                width: 100%;
                padding: 1.3rem 1.4rem;
                border: solid 1px #ccc;
                border-radius: 5px;
                margin-top:1rem;
                &:focus{
                    border: 1px solid #0A99FE;
                }
            }

        }
        &__delivery{
            text-align: left;
            width: 100%;
            h5{
                display: none;
                font-weight: 300;
            }
            table{
                width: 100%;

                tr td{
                    padding: .5rem 0;

                }
                .tfoot{
                    padding-top: 2rem;
                    font-weight: 700;
                    font-size: 1rem;
                    border: 1px solid #ccc
                }
            }
            p{
                display: none;
            }
            .btnAddress{
                margin-top:1.5rem;
                background: #333;
                color: white;
                cursor: pointer;
                border: none;
                border-radius: 5px;
                padding: 1rem;
                float: right;
                width: 100%;
            }
        }
    }

    @media screen and (min-width: 768px) {
        .addressContent{
            flex-direction: row;
            grid-gap: 10rem;
            &__suburb{
                width: 600px;
                p{
                    font-size: 2.3rem;
                }
                .group-row .input-form{
                    padding: 1.5rem 1.8rem;
                }
            }
            &__delivery{
                width: 400px;
                h5 {
                    display: block;
                }
                table{
                    .tfoot{
                        padding-top: 2rem;
                        font-weight: 700;
                        font-size: 1.2rem;
                        border: 1px solid #ccc
                    }
                }
                p {
                    padding:2rem 0;
                    display: block;
                }
                .btnAddress{
                    width: 10rem;
                }
            }
        }
    }

    .group-row{
        padding-bottom: 1.5rem;
        .label{
            margin-bottom: .5rem;
            font-weight: bold;
        }
    }

    .text-right{
        text-align: right;
    }

    .vs__search::placeholder,
    .vs__dropdown-toggle,
    .vs__dropdown-menu {
        /*
        background: #dfe5fb;
        border: none;
        color: #394066;
        text-transform: lowercase;
        font-variant: small-caps;
        */
    }

    .skeletonPrice {
        border-radius: 60px;
        width: 120px;
        height: 34px;
        position: relative;
        margin: 0 auto;
        overflow: hidden;
    }
    .skeletonPrice::before {
        content: '';
        display: block;
        position: absolute;
        left: -150px;
        top: 0;
        height: 100%;
        width: 150px;
        background: linear-gradient(to right, transparent 0%, red 50%, transparent 100%);
        animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
    }
    @keyframes load {
        from {
            left: -150px;
        }
        to   {
            left: 100%;
        }
    }

</style>
