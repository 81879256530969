

<template>
  <section class="bookingContent">
    <div v-show="isLoading" class="contentLoading">
      <div class="loader">Loading...</div>
    </div>
    <div class="booking__text">
      <div class="content">
        <article class="bookingContent__logo">
        <img
          style="width: 100px"
          src="https://www.powerchicken.hn/img/logo_delivery_desktop.cb0c0826.png"
          alt="logo"
        />
        </article>
        <article class="bookingContent__tagline">
          <h5>HORAS DISPONIBLES DE ENTREGA</h5>
          <p>¿Á que hora deseas que te entreguemos tu orden?</p>
        </article>
      </div>
    </div>
    <article class="bookingContent__hours">
      <div class="content">
        <!-- <h6>DOMINGO 9 DE MAYO</h6> -->
        <span v-for="(hour, index) in morning" :key="index">
          <input
            class="radio"
            type="radio"
            name="hour"
            :id="hour"
            :value="hour"
            @click="clickHour(index, 'morning')"
          />
          <label :for="hour">{{ hour.toUpperCase() }}</label>
        </span>
        <!-- <div class="morning">

        </div>
        <div class="afternoon">
          <h6>TARDE</h6>
          <span v-for="(hour, index) in afternoon" :key="index">
            <input
              class="radio"
              type="radio"
              name="hour"
              :id="hour"
              :value="hour"
              @click="clickHour(index, 'afternoon')"
            />
            <label :for="hour">{{ hour.toUpperCase() }}</label>
          </span>
        </div> -->
      </div>
      <button
        class="bookingContent__hours--button"
        @click="goToPay"
        :disabled="hourSelected === null ? true : false"
      >
        Ir al pago
      </button>
    </article>
  </section>
</template>

<style lang="css" >
/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&display=swap"); */
.bookingContent {
  font-family: "Montserrat", sans-serif;
  color: #333333;
  padding: 90px 48px 50px 48px;
  max-width: 1061px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
}
.bookingContent__tagline h5 {
  font-weight: lighter;
}
.bookingContent__tagline p {
  padding: 0;
  margin: 0;
  margin-bottom: 22px;
  width: 100%;
  font-size: 22px;
}
.bookingContent__hours {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
}

.bookingContent__hours .content {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(1, 1fr);
  justify-items: stretch;
  text-align: center;

  /* -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-gap: .5rem;
  gap: .5rem; */
}
.bookingContent__hours .morning,
.bookingContent__hours .afternoon {
  display: -webkit-flex;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(1, 2fr);
  text-align: center;
  justify-items: normal;
  /* -webkit-flex-direction: column;
  flex-direction: column;
  text-align: center;
  -webkit-gap: 1rem;
  gap: 1.2rem; */

}
.bookingContent__hours .morning h6,
.bookingContent__hours .afternoon h6,
.bookingContent__hours .content h6 {
  display: none;
}
.bookingContent__hours input[type="radio"] {
  display: none;
}
.bookingContent__hours label {
  padding: 1rem .5rem;
  border-radius: 3px;
  border: 1.8px solid #abbbc8;
  display: inline-block;
  position: relative;
  cursor: pointer;
  font-size: .8rem;
  font-weight: 400;
  width: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.bookingContent__hours label:hover {
  border-color: #0a99fe;
  background-color: #eff6fb;
}
.bookingContent__hours input[type="radio"]:checked + label {
  border-color: #0a99fe;
  background-color: #eff6fb;
}

.bookingContent__hours--button {
  font-family: inherit;
  margin-top: 2rem;
  background-color: #333;
  color: white;
  padding: 1.5rem 1rem;
  border: none;
  font-size: 1rem;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
}

/* @media (min-width: 321px) and (max-width: 374px){
  .bookingContent__hours label {
    padding: .7rem .5rem;
  }
} */

/* @media (min-width: 376px) and (max-width: 765px){
  .bookingContent__hours label {
    padding: .7rem 1.4rem;
  }
} */

@media screen and (min-width: 768px) {
  .bookingContent {
    padding:0 3rem;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: space-evenly;
    justify-content: space-evenly;
    grid-gap: 4rem;
  }
  .booking__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }


  .bookingContent__tagline p {

    font-size: 1.7rem;
  }
  .bookingContent__hours {
    width: 473px;
  }
  .bookingContent__hours .morning h6,
  .bookingContent__hours .afternoon h6,
  .bookingContent__hours .content h6 {
    display: block;
    margin-top: 0;
    font-weight: 300;
  }
  .bookingContent__hours .content {
    /* -webkit-justify-content: flex-end;
    justify-content: flex-end;
    -webkit-gap: 2rem;
    gap: 2rem; */
    justify-items: stretch;
  }
  .bookingContent__hours--button {
    padding: 1.2rem 1rem;
    font-size: .9rem;
    font-weight: 600;
    align-self: flex-end;
  }
}

@media screen and (min-width: 900px) {
  /* .booking__text {
    width: 526px;
  } */
  .bookingContent__tagline p {
    font-size: 2rem;
  }
}

@media (min-width: 1024px) {

  .booking__text .content{
    margin-top: 0;
  }

  .bookingContent__tagline p {
    font-size: 2.7rem;
  }
}


@media (min-width: 1025px){
  .bookingContent {
    padding: 0 0;
  }
}
/* Loading spinner */

.contentLoading{
  position: absolute;
  background-color: #fff;
  width: 100%;
  height: 100vh;
  z-index: 3;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
}

.contentLoading .loader{
  margin-top: 15%;
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: #21ac4e;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

</style>

<script>
import axios from "axios";
import Swal from 'sweetalert2/src/sweetalert2.js';

import 'sweetalert2/src/sweetalert2.scss';
export default {
  name: "sdrp",
  data() {
    return {
      hourSelected: null,
      morning: [],
      afternoon: [],
      returnUrl: "",
      amount: "",
      orderID: "",
      storeId: "",
      currency: "",
      tokenf: "",
      dataTemp: null,
      dataAlterated: null,
      isLoading: true,
    };
  },

  created() {
    this.getDataInit();
  },

  methods: {
    getDataInit() {
      const querystring = window.location.search;
      const params = new URLSearchParams(querystring);
      this.returnUrl = params.get("url");
      this.amount = params.get("amount");
      this.orderID = params.get("orderID");
      this.storeId = params.get("storeID");
      this.currency = params.get("refID");
      this.tokenf = params.get("token");
      if (this.storeId === "40993297") {
        this.nameShop = "Villanueva";
        this.webShop = "villanueva";
      }

      if (this.storeId === "31436259") {
        this.nameShop = "Progreso";
        this.webShop = "progreso";
      }

      if (this.storeId === "31552060") {
        this.nameShop = "SPS Palenque";
        this.webShop = "palenque";
      }

      if (this.storeId === "43188072") {
        this.nameShop = "SPS CityMall";
        this.webShop = "citymall";
      }

      if (this.storeId === "43195065") {
        this.nameShop = "SPS La Lima";
        this.webShop = "lalima";
      }

      if (this.storeId === "32268563") {
        this.nameShop = "Choloma";
        this.webShop = "choloma";
      }

      if (this.storeId === "44727221") {
        this.nameShop = "Tegucigalpa";
        this.webShop = "tegucigalpa";
      }

      if(this.storeId === null){
        this.storeId = "55436462";
        this.nameShop = "PruebaLocal";
        this.webShop = "PruebaLOcal";
        console.log('Este es un store de prueba');
      }

      let endpoint = "https://app-sega.azurewebsites.net/api/OrderMd/horarios?StoreId=" + this.storeId;
      axios
        .get(endpoint)
        .then((resp) => {
          this.filterData(resp.data.data);
          //this.morning;
          this.isLoading = false;
        })
        .catch((err) => console.log(err));
    },
    filterData(data) {
      var hours = data.map((v) => ({ hora: v.hora, cupos: v.cuposRestantes }));
      this.dataAlterated = hours;
      this.dataTemp = data;
      /* console.log(JSON.stringify(data)); */
      hours.forEach((element, index) => {
        if (element.hora !== "") {
          var isAmPm = this.verifySchedule(element.hora);
          if (
            (isAmPm == "AM" && element.cupos > 0) ||
            (isAmPm == "M" && element.cupos > 0)
          ) {
            const timeString = element.hora;
            const timeString12hr = new Date(
              "1970-01-01T" + timeString + "Z"
            ).toLocaleTimeString(
              {},
              {
                timeZone: "UTC",
                hour12: true,
                hour: "numeric",
                minute: "numeric",
              }
            );

            if (isAmPm == "M") {
              var timeMedium = element.hora;
              timeMedium = timeMedium.slice(0, 5)
              this.morning.push(timeMedium + " P. M.");
            } else {
              this.morning.push(timeString12hr.toUpperCase());
            }
          } else if (isAmPm == "PM" && element.cupos > 0) {
            const timeString = element.hora;
            const timeString12hr = new Date(
              "1970-01-01T" + timeString + "Z"
            ).toLocaleTimeString(
              {},
              {
                timeZone: "UTC",
                hour12: true,
                hour: "numeric",
                minute: "numeric",
              }
            );
            this.morning.push(timeString12hr);
            //this.afternoon.push(timeString12hr);
          }
        }
      });
    },

    isHourSelectedExist(hour){
      return axios
        .get(`https://app-sega.azurewebsites.net/api/OrderMd/horarios?StoreId=${this.storeId}`)
        .then((resp) => {
          let hours = resp.data.data;
          let hourExist = hours.filter(h => h.hora === hour && h.cuposRestantes > 0);
          if(hourExist.length > 0){
            return true;
          }else{
            return false;
          }
        })
        .catch((err) => console.log(err));
    },

    verifySchedule(hour) {
      var result = "";
      var numberHour = 0;
      numberHour = parseInt(hour);
      if (numberHour > 12) {
        result = "PM";
      } else if (numberHour == 12) {
        result = "M";
      } else {
        result = "AM";
      }
      /* if (numberHour >= 12) {
        result = "PM";
      } else {
        result = "AM";
      } */
      return result;
    },
    getMilitaryTime(time){
      let hours = Number(time.match(/^(\d+)/)[1]);
      let minutes = Number(time.match(/:(\d+)/)[1]);
      let AMPM = time.match(/\s(.*)$/)[1];
        //console.log(hours, minutes, AMPM);
      if(AMPM == "p. m." && hours<12) hours = hours+12;
      if(AMPM == "a. m." && hours==12) hours = hours-12;
      let sHours = hours.toString();
      let sMinutes = minutes.toString();
      if(hours<10) sHours = "0" + sHours;
      if(minutes<10) sMinutes = "0" + sMinutes;
      return sHours + ":" + sMinutes;
    },
    clickHour(index, arrayName){
      if(arrayName === 'morning'){
        this.hourSelected = this.dataTemp[index].hora
      }else if (arrayName === 'afternoon'){
        let size = this.morning.length;
        this.hourSelected = this.dataTemp[size+index].hora
      }
    },
    goToPay() {
      let bigScreens = window.matchMedia("(min-width: 768px)");
      document.querySelector('.contentApp').style.flexDirection = 'column';
      document.querySelector('.contentLogosCompany ').style.width = '100%';
      if(bigScreens.matches){
        document.querySelector('.wrapper').style.height = 'auto';
        document.querySelector('.wrapper').style.overflow = 'hidden';
      }else{
        document.querySelector('.wrapper').style.height = '100vh';
      }

      const hourExist = this.isHourSelectedExist(this.hourSelected);

      if(hourExist){
        this.$emit("onHourSelected", {
          hour: this.hourSelected,
          nextShowScreen: "pay",
        });
      }else{
        Swal.fire({
          title: 'Ya no hay cupos en la hora que seleccionaste, elige otra hora.',
          text: '',
          icon: 'question',
          confirmButtonText: 'Elegir otra hora'
        }).then(resp => {if(resp) window.location.reload()});
      }
    },
  }
};
</script>
