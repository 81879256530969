<template>
    <div v-if="hasErrors">
        <div class="font-medium text-white">
            No se realizo ningún cobro.
        </div>

        <ul class="mt-3 list-disc list-inside text-sm text-white">
            <li v-for="(error, key) in errors" :key="key">
                {{ error }}
            </li>
        </ul>
    </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: "ToastErrors",
    props: {
        errors: Array,
    },
    computed: {
        /*
        errors() {
            return this.props.errors
        },
        */

        hasErrors() {
            return Object.keys(this.errors).length > 0;
        },
    }
})
</script>

<style scoped>

</style>
